@use "../../common/styles/_mixins.scss" as *;

.CropperModal {
  display: grid;
  grid-template-columns: 1fr;
  // grid-template-rows: auto 1fr auto;
  max-height: 90vh;
  max-width: 90vw;

  &__header {
    margin-top: 16px;

    @include w480 {
      margin-top: 8px;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 18px;
    font-family: var(--font-proxima);
    line-height: 30px;
    color: var(--main-color-graphite-black);

    @include w480 {
      font-size: 14px;
    }
  }

  &__body {
    margin-top: 32px;
    width: 100%;

    @include w480 {
      margin-top: 16px;
    }
  }

  &__crop {
    width: 100%;
    overflow-y: hidden;
    overflow-y: auto;
    padding-right: 5px;
    max-height: 75vh;

    & > * {
      width: 100%;
      // min-height: 370px;
    }
  }

  &__image,
  &__image_wrapper {
    min-width: 100%;
    max-width: 100%;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    font-family: var(--font-proxima);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    // padding: 15.5px 115px;
    width: 234px;
    margin: 32px auto 0 auto;

    @include w480 {
      // padding: 14px 0 15px 0;
      width: 100%;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
