.home {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.video {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 130%;
  object-fit: cover;
}

.body-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 20%;
  left: 45%;
  @media screen and (max-width: 1622px) {
    bottom: 30%;
    left: 45%;
    transform: scale(0.7);
  }
  @media screen and (max-width: 960px) {
    left: 25%;
    bottom: 45%;
    transform: scale(0.9);
  }
  @media screen and (max-width: 660px) {
    width: 150%;
    left: 15%;
    bottom: 40%;
  }
}
