.Signin__form-items {
  display: grid;
  row-gap: 8px;
}

.Signin__control {
  margin-top: 26px;
}

.Signin__control,
.Signin__control-button {
  width: 338px;
}

.Signin__control-link {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.Signin__link {
  margin-left: 2px;
}

.Signin__link:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .Signin__form-items {
    row-gap: 12px;
  }

  .Signin__control,
  .Signin__control-button {
    width: 100%;
  }

  .Signin__control-button {
    padding-top: 15;
    padding-bottom: 15;
  }

  .Signin__control-link {
    margin-top: 30px;
  }
}
