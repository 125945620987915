@use "../../../common/styles/mixins" as *;

.Button {
  background-color: unset;
  border: none;
  cursor: pointer;
  outline: none;
  font-family: var(--font-proxima) !important;

  &_contained_nuar,
  &_outlined_nuar {
    border-radius: 8px;
    padding: 12px 26px 11px 26px;
    font-weight: 400;
    font-size: 18px;
    line-height: 139%;
  }

  &_contained_nuar {
    background-color: var(--main-color-gray-umber);
    color: var(--main-color-white);
  }

  &_outlined_nuar {
    background-color: #fff;

    border: 1px solid #303030;
    padding-top: 11px;
    padding-bottom: 10px;
    color: var(--main-color-gray-umber);
  }

  &_full_width {
    width: 100%;
    display: inline-block;
  }

  &__loader {
    max-height: 15px;
    min-height: 15px;
    max-width: 15px;
    min-width: 15px;
  }
}
