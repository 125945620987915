@use "../../common/styles/mixins" as *;

.CloseEventModal {
  padding: 32px;
  width: 469px;

  @include w480 {
    padding: 16px;
    width: 340px;
  }

  &__header {
    display: flex;
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;

    @include w480 {
      font-size: 20px;
      line-height: 20px;
    }
  }

  &__icon {
    cursor: pointer;
    max-height: 14.4px;
    min-height: 14.4px;
    max-width: 14.4px;
    min-width: 14.4px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 32px;
    justify-content: center;
  }

  &__button_cancel {
    max-width: 157px;

    @include w480 {
      font-size: 14px;
    }
  }

  &__button_close {
    margin-left: 31px;
    cursor: pointer;
  }
}
