.SubscribeMeets {
  display: grid;
  gap: 28px;
  &__box {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &__sign {
    color: #303030;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

}