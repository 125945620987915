.Meetings {
  &__image {
    width: 464px;
    height: 374px;
    position: absolute;
    right: 58px;
    bottom: 0px;
    z-index: 0;
  }

  display: grid;
  max-height: 541px;
  row-gap: 14px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

@media (max-width: 1024px) {
  .Meetings {
    &__image {
      width: 356px;
      height: 356px;
      right: 10px;
    }
  }
}

@media (max-width: 768px) {
  .Meetings {
    &__image {
      width: 262px;
      height: 238px;
      right: 0;
    }
  }
}