@use "../../common/styles/_mixins.scss" as *;

@keyframes show {
  from {
    opacity: 0;
    // transform: translateY(-5px);
  }
  to {
    opacity: 1;
    // transform: translateY(0);
  }
}

.EventPreview {
  animation: 0.3s show ease;
  background: var(--main-color-white);
  border-radius: 8px;
  color: var(--main-color-so-dark-grey);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 240px 1fr;
  min-height: 390px;
  max-width: 387px;
  min-width: 387px;
  height: fit-content;
  position: relative;
  height: 100%;

  @include w480 {
    max-width: 336px / 360px * 100vw;
    min-width: 336px / 360px * 100vw;
    grid-template-rows: 206.7px / 360px * 100vw 1fr;
  }

  &:hover {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
  }

  // HEADER
  &__header {
    background: linear-gradient(0deg, #f2f2f2, #f2f2f2);
    border-radius: 8px 8px 0 0;
    display: flex;
    overflow: hidden;
    position: relative;
  }

  // IMAGE
  &__preview {
    aspect-ratio: 1.625 / 1;
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 240px;
    object-fit: cover;

    @include w480 {
      height: 100%;
    }
  }

  // TAG DATE
  &__date {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  &__date_hide {
    display: none;
  }

  // TAGS
  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 8px 12px;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &__tag {
    @include w768 {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &__tag:nth-child(1) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  &__tag:nth-child(2) {
    grid-column: 2 / 3;
  }

  &__tag:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  // BODY
  &__body {
    padding: 17.5px 16px 16px 16px;
    border-radius: 0 0 8px 8px;
    border-top: unset;
    display: grid;
    gap: 20px;

    @include w768 {
      padding: 17.5px 9px 16px 8px;
    }

    @include w480 {
      padding: 17.5px 11px 16px 11px;
    }
  }

  &__list {
    display: grid;
    gap: 12px 0;
  }

  &__item {
    align-items: center;
    align-content: center;
    display: flex;
  }

  &__item_text {
    display: flex;
    align-items: center;
    gap: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.0666667px;
  }

  &__sign_rub {
    font-size: 14px;
  }

  &__item_issue {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--main-color-platinum-gray);
    letter-spacing: 0.0666667px;
    margin-left: 10px;
  }

  &__icon {
    margin-right: 13.33px;
    max-width: 16px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;
  }

  &__title {
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    line-height: 120%;
    max-width: 324px;
    // min-height: 48px;
    word-wrap: break-word;
  }

  &__counter {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  &__status {
    position: absolute;
    top: 12px;
    right: 14px;
  }

  // BUTTON
  &__controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__daysLeftTag {
    position: absolute;
    background-color: #ffffff;
    left: 14px;
    top: 14px;
    padding: 6px 8px;
    border-radius: 6px;

    font-size: 14px;
    line-height: 14px;

    color: #222426;

    &_tomorrow {
      background-color: #fdffa8;
    }

    &_noneDisplay {
      display: none;
    }
  }
}
