$small: 480px;
$medium: 768px;
$large: 1024px;

@mixin w480 {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin w768 {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin w1024 {
  @media (max-width: 1439px) {
    @content;
  }
}

@mixin w1440 {
  @media (max-width: 1919px) {
    @content;
  }
}

@mixin ifmobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin iftablet {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin iflaptop {
  @media (max-width: 1919px) {
    @content;
  }
}

@function z($value, $windowSize) {
  @return calc($value / $windowSize * 100vw);
}
