.BaseMessage {
  &_System {
    background-color: #f3f4f6;
    border-radius: 15px;
    padding: 10px;
    width: 100%;
  }

  &_User {
    background-color: #303030;
    border-radius: 100px;
    padding: 10px 14px;
    color: #ffffff;
    width: fit-content;
  }

  &__Content {
    display: flex;
    gap: 0 8px;
  }

  &__Icon {
    max-height: 28px;
    min-height: 28px;
    max-width: 28px;
    min-width: 28px;
  }

  &__Attach {
    margin-top: 8px;
  }

  &__Time {
    margin-top: 10px;
    font-size: 12px;
    text-align: right;
  }
}

.Attach {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0 4px;
  width: fit-content;
  padding: 0 0 0 37px;

  &__Text {
    color: #216bbf;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }

  &__Icon {
    max-height: 16px;
    min-height: 16px;
    max-width: 16px;
    min-width: 16px;
  }
}
