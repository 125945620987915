.menu {
  position: absolute;
  top: 30px;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 20px;
  right: -20px;
  z-index: 100;

  &__main-placeholder {
    color: var(--main-color-white);
  }

  &__account-placeholder {
    color: var(--main-color-black);
    text-transform: uppercase;
  }

  &__control::after {
    content: '';
    position: absolute;
    right: -20px;
    top: 8px;
    z-index: 1;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--main-color-white);
  }
}

.menu > div {
  width: max-content;
  cursor: pointer;
  color: black;
}

.menu > div + div {
  margin-top: 16px;
}

.label {
  position: relative;
  color: black;
  cursor: pointer;
  width: fit-content;
}

.label::after {
  cursor: initial;
  content: '';
  position: absolute;
  right: -20px;
  top: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}
