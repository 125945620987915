.Modal-score {
  &__inner {
    padding: 30px 0 0 0;
  }

  &__list {
    display: grid;
    row-gap: 30px;
  }

  &__label {
    padding: 0 0 20px 0;
    color: var(--main-color-dark-grey);
  }

  &__comment {
    max-height: 150px;
  }

  &__button {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .Modal-score {
    &__button {
      width: 100%;
      padding: 12px;
    }
  }
}
