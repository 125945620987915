@use "../../../common/styles/_mixins.scss" as *;

.AccountMeets {
  &__CategoryTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 21.6px;
  }

  &__CategorySubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.49px;
    color: #797983;
  }

  &__disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  &__title {
    line-height: normal;
    font-size: 32px;
    white-space: nowrap;

    @include w480 {
      font-size: 28px;
      line-height: normal;
    }
  }

  &__counter {
    color: #797983;
    white-space: nowrap;

    @include w480 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  &__box_buttons {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-self: right;
    gap: 20px;
    // margin-bottom: 30vw;

    @include w480 {
      // height: 100vw;
      justify-self: left;
      width: 100%;
      justify-content: center;
    }
  }

  &__button {
    font-size: 18px !important;
    padding: 12px 57px;

    @include w480 {
      padding: 12px 27px !important;
    }
  }
}

.Poll {
  &__text {
    white-space: nowrap;
  }

  &__input {
    height: 58px;
    min-height: 58px;
    max-height: 58px;

    @include w480 {
      height: 53px;
    }
  }

  &__grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    @include w480 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  &__gender_title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.49px;
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__grid_box {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 10px;

    @include w480 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 16px;
    }
  }

  &__grid_button {
    display: flex;
    justify-self: flex-end;
    align-items: center;
  }

  &__hide_text {
    @include w480 {
      display: none;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__notify {
    display: flex;
    gap: 20px;

    &__style {
      font-size: 16px;
      line-height: 20.8px;
    }

    @include w480 {
      flex-direction: column;
    }
  }
}

.Textarea {
  min-height: 133px;
  padding: 19px 16px;

  &:focus {
    outline: solid black;
    border-color: none;
  }
}
