.BaseToggle {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
}

.BaseToggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.BaseToggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.BaseToggle-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .BaseToggle-slider {
    background-color: #303030;
}

input:focus + .BaseToggle-slider {
    box-shadow: 0 0 1px #303030;
}

input:checked + .BaseToggle-slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}