.GoBack {
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 12.5px;
  width: fit-content;

  &__image {
    max-height: 11.67px;
    min-height: 11.67px;
    max-width: 15px;
    min-width: 15px;
  }

  &__text {
    font-weight: 400;
    font-size: 15px;
    line-height: 16.8px;

    &-white {
      color: #fff;
    }
  }
}
