.iconlist {
  &__wrapper {
    position: absolute;
    left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 59px;
    margin-right: 119px;
  }
  &__telegram {
    width: auto;
    transition: 0.3s;
    &:hover {
      transition: 0.3s;
      transform: rotate(-5deg);
    }
  }
  @media screen and (max-width: 1622px) {
    &__wrapper {
      position: static;
      column-gap: 36px;
      flex-direction: row;
      margin-right: 0;
      margin-bottom: 16px;
    }
    &__wrapper img {
      width: auto;
    }
  }
}
