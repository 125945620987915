@use "../../common/styles/_mixins.scss" as *;

.Table {
    background-color: inherit;
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;

    &__head {}

    &__head_row {}

    &__head_cell {
        color: #797983;
        padding-bottom: 8px;
    }

    &__body {}

    &__row {}

    &__cell {
        padding: 8px 0 8px 0;
    }

    &__head_cell,
    &__cell {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        @include w768 {
            font-size: 14px;
            line-height: 16px;
        }
    }
}