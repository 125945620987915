.ChatSendButton {
  cursor: pointer;
  align-items: center;
  background-color: #303030;
  border-radius: 50%;
  border: none;
  justify-content: center;
  display: flex;
  padding: 8px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;

  &_Disabled {
    opacity: 0.7;
  }

  &:hover {
    opacity: 0.7;
  }

  &__Loader {
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;

    & > * > * {
      stroke: white;
    }
  }
}
