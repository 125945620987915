.Credentials__inner {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.Credentials__header,
.Credentials__content {
  padding: 0 16px 0 100px;
}

.Credentials__header {
  padding-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Credentials-registration-step-1 {
  background-image: url('../../common/images/image-lines-reg.webp'),
    url('../../common/images/svg/LH_1440.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: right top, right 100px;
  background-size: 581px 100%, auto auto;
}

.Credentials-registration-step-2 {
  background-image: url('../../common/images/image-lines-reg.webp'),
    url('../../common/images/svg/LH_1440.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: right top, right top;
  background-size: 581px 100%, 550px auto;
}

.Credentials-registration-step-1-without-hand {
  background-image: url('../../common/images/image-lines-reg.webp');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 581px 100%;
}

.Credentials-poll-step-1,
.Credentials-poll-step-2 {
  position: relative;
  background-repeat: no-repeat, no-repeat;
  background-position: right top, right top;
  background-size: 581px 100%, auto auto;
}

.Credentials-poll-step-1::after,
.Credentials-poll-step-2::after {
  content: '';
  position: absolute;
  width: 51.01px;
  height: 65.89px;
  background: center / cover no-repeat
    url('../../common/images/image-single-black-seed.webp');
  right: 315px;
  top: 60%;
  z-index: -1;
}

.Credentials-poll-step-1 {
  background-image: url('../../common/images/image-lines-reg.webp'),
    url('../../common/images/image-poll-step-1-w1440.webp');
}

.Credentials-poll-step-1::after {
  transform: rotate(320deg);
}

.Credentials-poll-step-2 {
  background-image: url('../../common/images/image-lines-reg.webp'),
    url('../../common/images/image-poll-step-2-w1440.webp');
}

.Credentials-poll-step-2::after {
  transform: rotate(-350deg);
}

.Credentials-poll-step-3,
.Credentials-poll-step-4,
.Credentials-poll-step-5,
.Credentials-poll-step-6 {
  position: relative;
  background-image: url('../../common/images/image-lines-reg.webp');
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 581px 100%;
}

.Credentials-poll-step-3::after,
.Credentials-poll-step-4::after,
.Credentials-poll-step-5-page-1::after,
.Credentials-poll-step-5-page-2::after,
.Credentials-poll-step-6::after {
  content: '';
  position: absolute;
  bottom: 0;
}

.Credentials-poll-step-3::after {
  width: 111px;
  height: 353.97px;
  background: bottom / contain no-repeat
    url('../../common/images/image-tree-poll-step-3.webp');
  right: 315px;
}

.Credentials-poll-step-4::after {
  width: 257px;
  height: 480.11px;
  background: bottom / contain no-repeat
    url('../../common/images/image-tree-poll-step-4.webp');
  right: 235px;
}

.Credentials-poll-step-5-page-1::after,
.Credentials-poll-step-5-page-2::after {
  width: 564.41px;
  height: 534.37px;
  right: 80px;
}

.Credentials-poll-step-5-page-1::after {
  background: bottom / contain no-repeat
    url('../../common/images/image-tree-poll-step-5-p-2-w1440.webp');
}

.Credentials-poll-step-5-page-2::after {
  background: bottom / contain no-repeat
    url('../../common/images/image-tree-poll-step-5-p-1-w1440.webp');
}

.Credentials-poll-step-6::after {
  width: 564.41px;
  height: 534.37px;
  background: bottom / contain no-repeat
    url('../../common/images/image-tree-poll-step-6-w1440.webp');
  right: 80px;
}

@media (max-width: 1024px) {
  .Credentials__header,
  .Credentials__content {
    padding: 0 16px 0 60px;
  }

  .Credentials__header {
    padding-top: 50px;
  }

  .Credentials-registration-step-1 {
    background-image: url('../../common/images/image-lines-reg.webp'),
      url('../../common/images/svg/LH_1024.svg');
    background-size: 330px 100%, auto auto;
  }

  .Credentials-registration-step-1-without-hand {
    background-image: url('../../common/images/image-lines-reg.webp');
    background-size: 330px 100%;
  }

  .Credentials-registration-step-2 {
    background-image: url('../../common/images/image-lines-reg.webp'),
      url('../../common/images/svg/LH_1024.svg');
    background-size: 330px 100%, 37.5% auto;
  }

  .Credentials-poll-step-1,
  .Credentials-poll-step-2 {
    background-size: 330px 100%, auto auto;
  }

  .Credentials-poll-step-1 {
    background-image: url('../../common/images/image-lines-reg.webp'),
      url('../../common/images/image-poll-step-1-w1024.webp');
  }

  .Credentials-poll-step-2 {
    background-image: url('../../common/images/image-lines-reg.webp'),
      url('../../common/images/image-poll-step-2-w1024.webp');
  }

  .Credentials-poll-step-1::after,
  .Credentials-poll-step-2::after {
    width: 45.01px;
    height: 55.89px;
    right: 200px;
  }

  .Credentials-poll-step-1::after {
    top: 400px;
  }

  .Credentials-poll-step-1::after,
  .Credentials-poll-step-2::after {
    right: 150px;
  }

  .Credentials-poll-step-3::after {
    right: 128px;
  }

  .Credentials-poll-step-4::after {
    width: 214px;
    height: 397px;
    right: 84px;
  }

  .Credentials-poll-step-5-page-1::after,
  .Credentials-poll-step-5-page-2::after {
    width: 346px;
    right: 12px;
  }

  .Credentials-poll-step-5-page-1::after {
    background: bottom / contain no-repeat
      url('../../common/images/image-tree-poll-step-5-p-1-w1024.webp');
  }

  .Credentials-poll-step-5-page-2::after {
    background: bottom / contain no-repeat
      url('../../common/images/image-tree-poll-step-5-p-2-w1024.webp');
  }

  .Credentials-poll-step-6::after {
    background: bottom / contain no-repeat
      url('../../common/images/image-tree-poll-step-6-w1024.webp');
    width: 348px;
    right: 12px;
    z-index: 1;
  }

  .Credentials-poll-step-3,
  .Credentials-poll-step-4,
  .Credentials-poll-step-5,
  .Credentials-poll-step-6 {
    background-size: 330px 100%;
  }
}

@media (max-width: 768px) {
  .Credentials__header,
  .Credentials__content {
    padding: 0 16px 0 40px;
  }

  .Credentials__header {
    padding-top: 40px;
  }

  .Credentials-registration-step-1 {
    background-image: url('../../common/images/image-lines-reg.webp'),
      url('../../common/images/svg/LH_768.svg');
    background-size: 42% 100%, auto auto;
  }

  .Credentials-registration-step-1-without-hand {
    background-image: url('../../common/images/image-lines-reg.webp');
    background-size: 42% 100%;
  }

  .Credentials-registration-step-2 {
    background-image: url('../../common/images/image-lines-reg.webp'),
      url('../../common/images/svg/LH_768.svg');
  }

  .Credentials-poll-step-1,
  .Credentials-poll-step-2 {
    background-size: 295px 100%, auto auto;
  }

  .Credentials-poll-step-1 {
    background-image: url('../../common/images/image-lines-reg.webp'),
      url('../../common/images/image-poll-step-1-w768.webp');
  }

  .Credentials-poll-step-2 {
    background-image: url('../../common/images/image-lines-reg.webp'),
      url('../../common/images/image-poll-step-2-w768.webp');
  }

  .Credentials-poll-step-1::after {
    width: 33.01px;
    height: 43.89px;
    top: 376px;
  }

  .Credentials-poll-step-3::after {
    width: 87px;
    height: 279px;
  }

  .Credentials-poll-step-4::after {
    width: 166px;
    height: 309px;
    right: 58px;
  }

  .Credentials-poll-step-3,
  .Credentials-poll-step-4,
  .Credentials-poll-step-5,
  .Credentials-poll-step-6 {
    background-size: 295px 100%;
  }
}

@media (max-width: 480px) {
  .Credentials__header,
  .Credentials__content {
    padding: 0 10px;
  }

  .Credentials__header {
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Credentials__content {
    padding-bottom: 60px;
  }

  .Credentials__footer_position-fixed {
    // position: fixed;
    width: 100%;
    // bottom: 0px;
  }

  .Credentials-registration-step-1 {
    //background-image: url('../../common/images/image-registration-step-1-w360.webp');
    background-image: url('../../common/images/svg/LH_480.svg');
    background-position: right top;
    background-size: auto auto;
  }

  .Credentials-registration-step-1-without-hand {
    background-image: unset;
  }

  .Credentials-registration-step-2 {
    //background-image: url('../../common/images/image-registration-step-1-w360.webp');
    background-image: url('../../common/images/svg/LH_480.svg');
    background-position: right top;
    background-size: auto auto;
  }

  .Credentials-poll-step-1,
  .Credentials-poll-step-2 {
    background-image: url('../../common/images/image-poll-step-1-w360.webp');
    background-size: auto auto;
  }

  .Credentials-poll-step-1::after,
  .Credentials-poll-step-2::after {
    width: 33.39px;
    height: 45.89px;
    right: 157px;
  }

  .Credentials-poll-step-1::after {
    top: 360px;
    width: 43.39px;
    height: 55.89px;
    z-index: -1;
  }

  .Credentials-poll-step-3::after,
  .Credentials-poll-step-4::after {
    z-index: -1;
  }

  .Credentials-poll-step-3::after {
    bottom: 9px;
    right: calc(200px / 480px * 100vw);
  }

  .Credentials-poll-step-4::after {
    right: calc(150px / 480px * 100vw);
    bottom: 22px;
  }

  .Credentials-poll-step-5-page-1::after,
  .Credentials-poll-step-5-page-2::after,
  .Credentials-poll-step-6::after {
    width: calc(346px / 480px * 100vw);
    right: calc(64px / 480px * 100vw);
    height: calc(534.36px / 480px * 100vw);
    z-index: -1;
    bottom: 5%;
  }

  .Credentials-poll-step-5-page-2::after {
    bottom: 4.5%;
  }

  .Credentials-poll-step-6::after {
    background: bottom / contain no-repeat
      url('../../common/images/image-tree-poll-step-5-p-2-w1024.webp');
    bottom: 2%;
  }

  .Credentials-poll-step-3,
  .Credentials-poll-step-4,
  .Credentials-poll-step-5,
  .Credentials-poll-step-6 {
    background: none;
  }
}
