@use "src/common/styles/_mixins.scss" as *;

.Label {
  &__Text {
    font-family: var(--font-proxima);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 16px;

    @include ifmobile {
      line-height: 19.49px;
    }
  }
}
