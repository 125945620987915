@use "src/common/styles/_mixins.scss" as *;

.Input {
  border: 1px solid #d4d3d9;
  border-radius: 4px;
  padding: 19px 16px;
  font-family: var(--font-proxima);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.49px;
  width: 100%;
  color: black;

  @include ifmobile {
    font-size: 14px;
    padding: 13.3px 16px;
  }

  &:focus {
    outline: solid black;
  }

  &::placeholder {
    color: #797983;
  }

  &:disabled {
    background-color: white;
  }

  &_Error {
    border-color: var(--main-color-red);

    &:focus {
      border-color: white;
    }
  }
}

.TextAreaWrapper {
  position: relative;

  &__Counter {
    font-size: 11;
    line-height: 16px;
    letter-spacing: 0.03999999910593033px;
    display: flex;
    position: absolute;
    right: 19px;
    bottom: 7px;
  }
}

.TextArea {
  resize: none;
  overflow: hidden;
  @include ifmobile {
    // min-height: 48px !important;
    min-height: 48px;
  }
}
