.container {
  position: fixed;
  z-index: 9999;
  top: 50px;

  .toast {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 8px;
    max-width: 490px;
    width: 490px;
    position: absolute;
    transition: all 0.5s ease-out;
    padding: 19.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
      align-items: center;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0 28px;

      .icon {
        max-width: 26px;
        min-width: 26px;
        max-height: 26px;
        min-height: 26px;
      }
    }

    .x {
      cursor: pointer;
    }
  }
}

@media (max-width: 480px) {
  .container {
    .toast {
      max-width: 340px;
      width: 340px;

      .content {
        font-size: 16px;

        .icon {}
      }

      .x {}
    }
  }
}