@use "../../common/styles/mixins" as *;

.EventPageLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100%;
}

.EventPage {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  grid-template-columns: 1fr;
  height: max-content;

  // grid-template-columns: auto 1fr;
  // gap: 20px 32px;
  color: var(--main-color-graphite-black);

  padding-top: 425px;

  @include w1440 {
    padding-top: 437px;
  }
  @include w1024 {
    padding-top: 410px;
    justify-content: center;
    gap: 54px;
  }

  @include w768 {
    padding-top: 400px;
  }

  @include w480 {
    padding-top: 416.5px;
  }

  &__block_go_back {
    transform: scale(1.2);
    position: absolute;
    left: 65px;
    top: 140px;

    @include w1024 {
      top: 115px;
    }

    @include w480 {
      top: 85px;
      left: 30px;
    }
  }

  &__block_cover {
    @include w1024 {
      grid-row: 2 / 3;
    }
  }

  &__block_info {
    @include w1024 {
      grid-row: 4 / 5;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 120%;
    word-wrap: break-word;
  }

  &__image {
    border-radius: 8px;
    height: 321px;
    width: 525px;

    @include w480 {
      height: 227.87px / 360px * 100vw;
      width: 338px / 360px * 100vw;
      object-fit: cover;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 4px;
    margin-top: 26px;

    @include w480 {
      margin-top: 18px;
    }
  }

  &__tag:nth-child(2) {
    background: #dedede;
  }

  &__tag:nth-child(3) {
    background: rgba(18, 25, 33, 0.7);
    color: var(--main-color-white);
  }

  &__text {
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    color: var(--main-color-white);

    @include w1024 {
      font-size: 42px;
    }
    @include w768 {
      font-size: 38px;
    }
    @include w480 {
      font-size: 26px;
    }
  }

  &__params {
    display: grid;

    gap: 16.5px;

    @include w768 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include w480 {
      grid-template-columns: (1fr);
      gap: 12px;
      margin-top: 20px;
    }
  }

  &__param {
    align-items: center;
    display: flex;
  }

  &__param_icon {
    margin-right: 20px;

    max-height: 34px;
    min-height: 34px;
    max-width: 34px;
    min-width: 34px;
  }

  &__param_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.0666667px;

    color: #3d3d3d;
  }

  &__sign_rub {
    font-size: 15px;
  }

  &__param_issue {
    color: var(--main-color-platinum-gray);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 20px;
    white-space: nowrap;
  }

  &__controls {
    align-items: center;
    display: flex;
    margin-top: 34px;

    gap: 30px;

    @include w1024 {
      margin-top: 32px;
    }

    @include w480 {
      flex-direction: column-reverse;
      gap: 10px;
    }
  }

  &__button {
    max-width: 210px;

    @include w480 {
      max-width: 100%;
      width: 100% !important;
      margin-top: 24px;
    }
  }

  &__link {
    align-items: center;
    cursor: pointer;
    color: var(--main-color-so-dark-grey);
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    position: relative;
  }

  &__tooltype {
    position: absolute;
    right: -26%;
    bottom: 150%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    @include w480 {
      right: -7%;
    }
  }

  &__chain_icon {
    margin-left: 10.75px;
  }

  &__topWrapper {
    position: absolute;
    height: 500px;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 50px;

    @include w1024 {
      margin-bottom: 57px;
    }

    @include w768 {
      margin-bottom: 50px;
      display: flex;
      justify-content: flex-start;
    }

    @include w480 {
      margin-bottom: 36px;
      padding-bottom: 36px;
    }
  }

  &__topWrapper-contentBlock {
    max-width: 1240px;
    min-width: 1240px;
    display: grid;
    justify-content: flex-start;

    z-index: 2;

    @include w1024 {
      max-width: 904px;
      min-width: 904px;
    }

    @include w768 {
      max-width: 100%;
      min-width: 100%;
      padding: 0 34px;
    }

    @include w480 {
      max-width: 100%;
      min-width: 100%;
      padding: 0 11px;
    }
  }
  &__ContentWrapper {
    width: 810px;
    @include w1024 {
      width: 548px;
    }
    @include w768 {
      width: 100%;
    }
    @include w480 {
      width: 100%;
    }
  }

  &__ContentWrapper_title {
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;

    margin-bottom: 16px;

    color: #1a1a1a;

    @include w1024 {
      font-size: 26px;
    }
    @include w768 {
      font-size: 26px;
    }
    @include w480 {
      font-size: 24px;
    }
  }

  &__ContentWrapper_subTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;

    color: #1a1a1a;

    @include w1024 {
      font-size: 22px;
    }
    @include w768 {
      font-size: 22px;
    }
    @include w480 {
      font-size: 24px;
    }
  }

  &__priceItem {
    margin-top: 15px;

    &__line {
      background-color: #ececec;
      border: 1px solid #ececec;
    }

    &__name {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.0666667px;

      color: #3d3d3d;

      margin-top: 15px;
    }

    &__price {
      display: flex;
      gap: 5px;
      align-items: center;
      width: max-content;
      padding: 7px 8px 4px;

      margin-top: 12px;

      background: #f0edff;
      border-radius: 4px;

      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      color: #3d3d3d;

      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;

      @include w480 {
        margin-top: 8px;
      }
    }
  }

  &_LinksWrapper {
    margin-top: 38px;
    display: grid;
    gap: 16px;

    padding-bottom: 26px;

    border-bottom: 1px solid #1a1a1a26;
    @include w480 {
      margin-top: 32px;

      h4 {
        font-size: 18px !important;
      }
    }
  }

  &_LinksWrapper__titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_LinksWrapper__linkElement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-shrink: 0;

    @include w480 {
      display: block;
    }
  }
  &_LinksWrapper__linksBlock {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 0;
  }

  &_LinksWrapper__empty {
    font-size: 16px;
    line-height: 150%;

    color: rgba(26, 26, 26, 0.5);
  }

  &_LinksWrapper__linkChat {
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 10px;

    border: 1px solid rgba(61, 61, 61, 0.24);
    border-radius: 8px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: #303030;

    @include w1024 {
      display: none;
    }
    @include w768 {
      display: flex;
    }
    @include w480 {
      width: 100%;
      margin-top: 26px;
    }

    &_w1024 {
      display: none;
      @include w1024 {
        display: flex;
      }
      @include w768 {
        display: none;
      }
      @include w480 {
        display: none;
      }
    }

    &:hover {
      color: #3677c2;
    }

    &:active {
      color: #303030;
      border-color: #3d3d3d85;
    }
  }

  &_LinksWrapper__link {
    display: flex;
    align-items: center;

    a {
      font-size: 16px;
      line-height: 19px;

      color: #3677c2;

      padding-left: 8px;

      &:hover {
        color: #003776;
      }

      &:active {
        color: #003776;
        opacity: 0.8;
      }
    }
  }

  &_LinksWrapper__slesh78 {
    padding: 0 12px 0 18px;

    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #3d3d3d;

    @include w480 {
      padding: 0 10px;
    }
  }

  &_InfoWrapper {
    width: 302px;
  }
}

.ToolType {
  background: #fdfdfd;
  border: 1px solid #d4d4d8;
  border-radius: 8px;
  padding: 8px 12px;
  width: fit-content;
  color: var(--main-color-graphite-black);

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 46%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #d4d4d8;
    clear: both;
  }
}

.mobileParamsWrapper {
  display: none;

  @include w768 {
    display: block;
    margin-top: 40px;
  }

  @include w480 {
    margin-top: 32px;
  }
}

.laptopParamsWrapper {
  @include w768 {
    display: none;
  }
}
