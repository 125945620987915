.ContactBio {
  &__Name {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.49px;
  }

  &__Info {
    opacity: 0.7;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.05px;
    margin-top: 2;
  }
}
