@use "../../../common/styles/mixins" as *;

.Tag {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: 0.25s;
  white-space: nowrap;
  width: fit-content;
  height: fit-content;

  &_event_preview {
    background: rgba(18, 25, 33, 0.7);
    border: 1px solid var(--main-color-black);
    border-radius: 16px;
    color: var(--main-color-white);
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.0666667px;
    padding: 6.5px 12px;
  }

  &_event_filter_date {
    background: var(--main-color-white);
    border: 1px solid var(--main-color-graphite-black);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 12px;
  }

  &_event_filter_date:hover,
  &_event_filter_date_active {
    background: var(--main-color-black);
    color: var(--main-color-white);

    & > * > * {
      stroke: var(--main-color-white);
    }
  }

  &_event_filter_category {
    background: var(--main-color-white);
    border: 1px solid rgba(26, 26, 26, 0.1);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    padding: 6px 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &_event_filter_category:hover,
  &_event_filter_category_active {
    background: #ebebeb;
    border: 1px solid var(--main-color-graphite-black);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  }

  &_event_filter_prank {
    position: relative;
    background: linear-gradient(
      125.43deg,
      #fdaac0 1.32%,
      #ddaffd 33.68%,
      #bedbff 66.05%,
      #bedbff 98.42%
    );
    background-size: 200% 200%;
    animation: gradient 5s linear infinite;
    border: 0px solid transparent;
    border-radius: 100px;
    border-image-slice: 1;

    span {
      z-index: 2;
    }

    &::after {
      background-color: white;
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      content: " ";
      border-radius: 100px;
      z-index: 0;
    }
  }

  &_event_filter_prank:hover {
    background: linear-gradient(
      125.43deg,
      #fdaac0 1.32%,
      #ddaffd 33.68%,
      #bedbff 66.05%,
      #bedbff 98.42%
    );
    border: 0;

    &::after {
      background-color: #f0f0ff;
    }
  }
  &_event_filter_prank_active {
    &::after {
      background-color: #e3e7ff;
    }
  }

  &_event_filter_category_clear {
    border-radius: 100px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding: 6px 12px;

    &:hover {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      background: #ebebeb;
    }
  }

  &_event_date {
    border-radius: 6px;
    color: var(--main-color-black-brown);
    padding: 6px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  &_event_date_yellow {
    background: var(--main-color-light-sand);
  }

  &_event_date_white {
    background-color: var(--main-color-white);
  }

  &_event_details {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.49px;
    letter-spacing: 0.0666667px;
    color: var(--main-color-graphite-black);
    background: var(--main-color-white);
    border: 1px solid var(--main-color-black);

    border-radius: 100px;
    padding: 12px 16px;

    @include w768 {
      padding: 10px 14px;
      font-size: 14px;
      font-weight: 17.05px;
    }

    @include w480 {
      padding: 5px 10px;
      font-size: 12px;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
