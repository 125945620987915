@use "../../common/styles/mixins" as *;

.Logo {
  width: fit-content;
  &__img {
    width: 124px;
    height: 33px;
    cursor: pointer;

    @include w480 {
      width: 100px;
      height: 26px;
    }
  }

  &__img_white > * {
    stroke: red;
    fill: white;
  }
}
