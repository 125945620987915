@use "../../common/styles/_mixins.scss" as *;

.SelectOutlined {
  position: relative;

  &__input {
    cursor: pointer;
    color: var(--main-color-so-dark-grey);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    position: relative;

    @include w480 {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__input::after {
    content: "";
    max-height: 11.67px;
    min-height: 11.67px;
    max-width: 11.67px;
    min-width: 11.67px;
    position: absolute;
    background: center / contain no-repeat
      url("../../common/svg/svg-arrow-down.svg");
    right: -20.34px;
    top: 25%;

    @include w480 {
      top: 12%;
    }
  }

  &__drowpdown {
    background: #ffffff;
    border: 1px solid #000000;
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    max-width: fit-content;
    min-width: 270px;
    padding: 20px 5px 20px 20px;
    position: absolute;
    z-index: 9;
    top: 140%;
  }

  &__list {
    display: grid;
    gap: 16px 0;
    max-height: 100px;
    min-height: fit-content;
    overflow-y: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #d9d9d9;
  }

  // LIST SCROLL
  &__list::-webkit-scrollbar {
    height: 50px;
    width: 3px;
  }

  &__list::-webkit-scrollbar-track {
    background: var(--main-color-white);
    border-radius: 9px;
  }

  &__list::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 9px;
  }

  &__item {
    cursor: pointer;
    transition: 0.25s;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 0 10px;
    padding-right: 10px;
  }

  &__item:hover {
    color: ver(--main-color-so-dark-grey);
  }

  &__option_icon {
    margin-left: 1rem;
  }
}
