.container {
  padding-top: 0;
  max-width: 100%;
}
@media screen and (max-width: 1622px) {
  .container {
    padding-left: 97px;
    padding-right: 97px;
    max-width: 100%;
  }
  .container.main {
    padding-right: 64px;
  }
}
@media screen and (max-width: 960px) {
  .container {
    padding-left: 50px;
    padding-right: 20px;
  }
  .container.main {
    padding-right: 20px;
  }
}
@media screen and (max-width: 660px) {
  .container {
    padding-left: 19px;
    padding-right: 25px;
    max-width: 100%;
  }
  .container.main {
    padding-right: 25px;
  }
}
