@use "src/common/styles/_mixins.scss" as *;

.RegistrationForm {
  display: grid;
  gap: 22px 17px;

  @include ifmobile {
    gap: 14px 0;
  }

  &__Checkbox {
    margin-top: 20px;

    @include iftablet {
      margin-top: 60px;
    }

    @include ifmobile {
      margin-top: 26px;
    }
  }

  & > *:nth-child(1) {
    grid-column: 1 / 2;
  }

  & > *:nth-child(2) {
    grid-column: 2 / 3;
  }

  & > *:nth-child(1),
  & > *:nth-child(2) {
    @include ifmobile {
      grid-column: 1 / 3;
    }
  }

  & > *:not(:nth-child(1), :nth-child(2)) {
    grid-column: 1 / 3;
  }
}

.RegistrationFormInput {
  &__Issue {
    color: #797983;
    margin: 16px 0 0 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.05px;

    @include iflaptop {
      font-size: 14px;
      line-height: 14px;
    }

    @include ifmobile {
      font-size: 12px;
      line-height: 14.62px;
      margin: 8px 0 0 0;
    }
  }
}

.RegistrationFormCheckbox {
  align-items: center;
  display: flex;
  gap: 0 12px;

  @include ifmobile {
    gap: 0 11px;
  }

  &__Text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.49px;
    color: #797983;
    max-width: 362px;

    @include ifmobile {
      font-size: 14px;
      line-height: 17.05px;
    }
  }

  &__Link {
    text-decoration: underline;
    color: #333236;
  }
}
