@use "src/common/styles/_mixins.scss" as *;

.Chips {
  display: flex;
  gap: 0 12px;

  @include ifmobile {
    gap: 0 6px;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.Chip {
  border-radius: 100px;
  background-color: #f1f1f1;
  color: #333236;
  cursor: pointer;
  transition: 0.25s;
  width: fit-content;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;

  @include ifmobile {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 14px;
  }

  &:hover,
  &_Active {
    background-color: #333236;
    color: white;
  }

  &:not(.Chip_Active):hover {
    opacity: 0.5;
  }
}
