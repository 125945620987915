.SigninConfirmEmail__inner {
  max-width: 756px;
  margin: 0 auto;
}

.SigninConfirmEmail__title,
.SigninConfirmEmail__text {
  text-align: center;
}

.SigninConfirmEmail__title {
  margin-top: 118px;
}

.SigninConfirmEmail__text {
  margin-top: 30px;
}

.SigninConfirmEmail__image-wrapper {
  width: 464px;
  height: 334px;
  margin: 80px auto 0 auto;
}

.SigninConfirmEmail__image {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  .SigninConfirmEmail__title {
    margin-top: 80px;
  }

  .SigninConfirmEmail__image {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .SigninConfirmEmail__title {
    margin-top: 70px;
  }

  .SigninConfirmEmail__text {
    margin-top: 33px;
  }

  .SigninConfirmEmail__image {
    margin-top: 68px;
  }
}

@media (max-width: 480px) {
  .SigninConfirmEmail__title {
    margin-top: 60px;
  }

  .SigninConfirmEmail__text {
    margin-top: 12px;
  }

  .SigninConfirmEmail__image {
    width: 299px;
    height: 215px;
  }

  .SigninConfirmEmail__image {
    margin-top: 42px;
  }
}
