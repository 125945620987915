@use "src/common/styles/_mixins.scss" as *;

.CategoryList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @include ifmobile {
    gap: 7px;
  }

  &__Item {
    align-items: center;
    cursor: pointer;
    background-color: #f3f4f6;
    color: #333236;
    display: flex;
    gap: 0 6px;
    border-radius: 43px;
    padding: 12px 14px;
    width: fit-content;
    transition: 0.25s;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;

    @include ifmobile {
      padding: 10.5px 14px;
      font-size: 14px;
      line-height: 17.05px;
    }

    &:not([data-key="active"]):hover {
      background-color: #333236;
      color: #f3f4f6;
    }
  }

  &__Icon {
    min-height: 14px;
    max-height: 14px;
    min-width: 14px;
    max-width: 14px;
  }
}
