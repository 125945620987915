@use "src/common/styles/_mixins.scss" as *;

.RegistrationForm {
  &__Block:not(:nth-child(1)) {
    margin-top: 28px;

    @include ifmobile {
      margin-top: 16px;
    }
  }

  &__Fields:not(:nth-child(4)) {
    margin-top: 16px;
  }

  &__Fields:nth-child(3) {
    margin-top: 8px;
  }
}

.FormBlock {
  &__Title {
    font-size: 21px;
    font-weight: 600;
    line-height: 27.3px;
    color: #000000;

    @include iflaptop {
      font-size: 18px;
      line-height: 23.4px;
    }
  }

  &__Text {
    color: #797983;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.49px;
    margin-top: 8px;

    @include ifmobile {
      font-size: 13px;
    }
  }
}

$fields-gap: 14px 31px;

.AboutMeFields {
  display: grid;
  gap: $fields-gap;
  justify-content: space-between;

  & > *:nth-child(2) {
    grid-column: 1 / 2;
    max-width: 245px;
    min-width: 245px;
  }

  & > *:nth-child(3) {
    grid-column: 2 / 3;
    max-width: 324px;
    min-width: 324px;
  }

  & > *:nth-child(2),
  & > *:nth-child(3) {
    @include ifmobile {
      max-width: 100%;
      min-width: unset;
      width: 100%;
      grid-column: 1 / 3;
    }
  }

  & > *:not(:nth-child(2), :nth-child(3), :nth-child(5)) {
    grid-column: 1 / 3;
  }

  &__CheckboxLabel {
    display: flex;
    align-items: center;
    gap: 0 12px;
    color: #797983;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.49px;

    @include ifmobile {
      font-size: 14px;
      line-height: 17.05px;
      gap: 0 8px;
    }
  }
}

.AvatarFields {
  & > * {
    @include ifmobile {
      height: 240px;
      width: 330px;
    }
  }
}

.AvatarPreview {
  position: relative;
  width: fit-content;
  height: 240px;

  &__Image {
    border-radius: 8px;
    object-fit: cover;
    height: 240px;
    width: 390px;

    @include ifmobile {
      width: 330px;
    }
  }

  &:hover > &__Overflow {
    opacity: 1;
  }

  &__Overflow {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    transition: 0.25s;
    position: absolute;
    background-color: #00000080;
    z-index: 2;
    opacity: 0;
  }

  &__Button {
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0px 25px 50px -12px #00000040;

    &:hover > * > * {
      stroke: #ef4343;
    }

    & > * > * {
      transition: 0.25s;
    }
  }
}

.CategoryFields {
  display: flex;
  flex-flow: column;
  gap: 8px 0;

  &__Block {
    background-color: #f3f4f6;
    border-radius: 6px;
    padding: 16px;
  }

  &__Block:nth-child(1) > *:nth-child(2),
  &__Block:nth-child(2) > *:nth-child(2) {
    margin-top: 24px;

    @include ifmobile {
      margin-top: 22px;
    }
  }

  &__Block:nth-child(1) > *:nth-child(3) {
    margin-top: 34px;

    @include ifmobile {
      margin-top: 32px;
    }
  }

  &__Label {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.49px;

    @include ifmobile {
      font-size: 14px;
      line-height: 17.05px;
    }
  }

  &__Label_Bold {
    font-weight: 600;

    @include ifmobile {
      font-weight: 400;
    }
  }

  &__Label_Thin {
    font-weight: 400;
  }

  &__Slider {
    margin-top: 14px;
  }

  &__Checkboxes {
    display: flex;
    justify-content: space-between;
    gap: 0 9px;
    margin-top: 4px;
  }

  &__Checkbox {
    width: 100%;
  }

  &__Romantic {
    display: flex;
    align-items: center;
    gap: 0 4px;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.83px;
    margin-top: 4px;

    @include ifmobile {
      align-items: flex-start;
    }
  }

  &__RomanticIcon {
    min-width: 12px;
    min-height: 10px;
  }
}

.SocialFields {
  display: flex;
  flex-flow: column;
  gap: 16px 0;

  &__Input > * {
    font-size: 14px;
  }
}

// .ImageUploader {
//   width: 316px;
//   height: 316px;
// }
