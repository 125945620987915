.ProfileCard {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;

  &__Header {
    align-items: flex-start;
    display: flex;
    gap: 0 10px;
  }

  &__AboutMe {
    color: #333236;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin-top: 16px;
  }

  &__Tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 16px;
  }

  &__Tag {
    background-color: #ffe2eb;
    border-radius: 100px;
    color: #333236;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    padding: 2px 6px;
  }

  &__Divider {
    background-color: #1a1a1a1f;
    margin-top: 12px;
    opacity: 0.25;
  }

  &__Socials {
    margin-top: 12px;
  }

  &__Romantic {
    margin-top: 12px;
  }
}

.RomanticInfo {
  align-items: center;
  display: flex;
  gap: 0 4px;

  &__Icon {
    max-height: 22px;
    min-height: 22px;
    max-width: 22px;
    min-width: 22px;
  }

  &__Text {
    color: #333236;
    opacity: 0.7;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
}
