.base-validation {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;

  color: #d4d3d9;

  &_valid {
    color: black;
  }

  &__img {
    padding-right: 15px;
    color: black;
    width: fit-content;
  }
}

@media (max-width: 480px) {
  .base-validation {
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
    /* letter-spacing: 0.2px; */
  }
}
