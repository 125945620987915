.Meeting {
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 1px 1px 0px #000000;
  border-radius: 8px;
  padding: 16px 30px;
  max-width: 800px;

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__date {
    margin-top: 5px;
  }

  &__user {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }

  &__details {
    display: flex;
    align-items: center;
  }

  &__details-item {
    color: var(--main-color-light-grey);
    margin-right: 18px;
  }

  &__details-item:nth-of-type(2) {
    margin-right: 8px;
  }

  &__copy-icon {
    cursor: pointer;
  }

  &__info {
    text-align: end;
  }

  &__additional {
    display: grid;
    grid-template-columns: auto;
  }

  &__body {
    display: grid;
    grid-template-columns: minmax(auto, 340px) auto;
    margin-top: 7.5px;
  }

  &__comment {
    color: var(--main-color-light-grey);
  }

  &__additional {
    justify-self: end;
  }

  &__button {
    padding: 12px 20px !important;
    font-size: 14px;
    margin-top: 10px;
    width: fit-content;
    text-transform: unset !important;
  }

  &__tags {
    align-content: flex-start;
    justify-content: flex-end;
  }

  &__image-user {
    max-width: 27px;
    min-width: 27px;
    max-height: 27px;
    min-height: 27px;
  }
}

@media (max-width: 1024px) {
  .Meeting {
    max-width: 570px;

    &__title {
      font-size: 22px;
    }
  }
}

@media (max-width: 768px) {
  .Meeting {
    padding: 6px 10px;
    max-width: 445px;

    &__header {
      display: grid;
      grid-template-columns: 1fr auto;
    }

    &__body {
      grid-template-columns: 1fr;
    }

    &__tags {
      justify-content: flex-start;
      margin-top: 15px;
    }

    &__additional {
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .Meeting {
    &__details-item {
      font-size: 14px !important;
    }
  }
}
