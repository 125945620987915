@use "../../common/styles/_extends.scss" as *;
@use "../../common/styles/_mixins.scss" as *;

.ProfileTitle {
  display: flex;
  justify-content: space-between;

  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 38.98px;
    margin-bottom: 26px;

    @include ifmobile {
      font-size: 26px;
      font-weight: 600;
      line-height: 31.67px;
      margin-bottom: 18px;
    }
  }
}
