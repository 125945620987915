.base-input {
  width: 100%;
  max-width: 100%;
  height: 58px;
  border: 1px solid var(--main-color-gray);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;

  font-size: 16px;
  padding: 19px 19px 20px 16px;
  font-family: "Roboto", sans-serif;

  user-select: none;
  cursor: text;

  &_required {
    border: 1px solid var(--main-color-red);
  }

  &_required::placeholder {
    color: var(--main-color-red);
  }

  &_temporarilyDisabled {
    background: #f3f4f6;
  }
}

.base-input:focus {
  color: var(--main-color-black);
}
.base-input:disabled {
  color: var(--main-color-light-grey);
}

.base-input::-webkit-input-placeholder {
  color: var(--main-color-dark-grey);
}

.base-input::placeholder {
  color: var(--main-color-dark-grey);
}

@media (max-width: 480px) {
  .base-input {
    height: 48px;
  }
}

.base-number-input-container {
  width: 100%;
  max-width: 100%;
  height: 58px;
  border: 1px solid var(--main-color-gray);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;

  display: flex;

  overflow: hidden;

  &__numberContainer {
    height: 100%;
    aspect-ratio: 1/1;
    background-color: #f3f4f6;
    display: grid;
    place-items: center;

    &__number {
      font-family: "Proxima Nova";
      position: relative;

      font-size: 12px;
      line-height: 15px;

      text-align: center;

      color: #6b7280;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        width: 20px;
        height: 20px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid #6b7280;
      }
    }
  }

  input {
    width: 100%;

    border: none;
    outline: none;

    font-size: 16px;
    padding: 19px 19px 20px 16px;
    font-family: "Roboto", sans-serif;

    user-select: none;
    cursor: text;
  }
}
