@use "../../common/styles/mixins" as *;
@use "../../common/styles/variables" as *;
@use "../../common/styles/extends" as *;

.NavBar {
  position: absolute;
  width: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  font-family: var(--font-roboto);

  @extend %header;

  &_account {
    font-family: var(--font-proxima) !important;
    position: unset;
  }

  &__inner {
    width: 100%;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__menu {
    justify-self: center;
  }

  &__socials_account {
    display: none !important;
  }

  &__logo {
    display: none;
  }

  &__logo_account {
    display: block;
  }

  &__controls {
    justify-self: end;
    display: flex;

    @include w1440 {
      margin-left: 40px;
    }
    @include w1024 {
      margin-left: 0;
    }
  }
}

.NavBar-socials {
  display: flex;
  align-items: center;

  &__telegram-icon {
    max-width: calc(40px / 1920px * 100vw);
    min-width: calc(40px / 1920px * 100vw);
    max-height: calc(40px / 1920px * 100vw);
    min-height: calc(40px / 1920px * 100vw);
  }

  &__contacts {
    margin-left: 21px;
  }

  &__contact {
    color: var(--main-color-white);
    font-size: calc(20px / 1920px * 100vw);
    font-weight: 700;
  }
}

.NavBar-menu {
  &__list {
    display: flex;
    align-items: center;
    column-gap: 52px;
    white-space: nowrap;
  }

  &__link {
    color: var(--main-color-white);
    font-size: calc(18px / 1920px * 100vw);
    font-weight: 600;
    text-transform: uppercase !important;
    line-height: calc(21px / 1920px * 100vw);
  }

  &__link_account {
    font-family: var(--font-proxima) !important;
    color: var(--main-color-black);
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase !important;

    @include w1024 {
      color: var(--main-color-white);
    }
  }

  &__burger-menu-icon {
    cursor: pointer;
  }
}

@media (max-width: 1439px) {
  .NavBar {
    //padding: 16px 20px;

    &__inner {
      grid-template-columns: auto 1fr 44px;
      column-gap: 16px;
    }

    &__socials {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    &__menu {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
    }

    &__controls {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      justify-self: end;
    }

    &__burger-icon {
      width: 44px;
      height: 44px;
    }
  }

  .NavBar-socials {
    &__contacts {
      display: none;
    }

    &__telegram-icon {
      max-width: 23px;
      min-width: 23px;
      max-height: 21px;
      min-height: 21px;
    }

    &__contact {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .NavBar-menu {
    &__list {
      position: absolute;
      top: -100%;
      display: none;
    }

    &__item:nth-of-type(6) > &__link,
    &__item:nth-of-type(7) > &__link {
      text-transform: capitalize;
    }

    &__link {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
    }

    &__burger {
      align-items: center;
      background-color: var(--main-color-black);
      border-radius: calc(5px / 768px * 100vw);
      display: flex;
      justify-content: center;
      width: 44px;
      height: 44px;
      position: relative;
      z-index: 11;
    }

    &__burger_events {
      background-color: unset;
    }

    &__burger-icon_open {
      min-width: 44px;
      max-width: 44px;
      min-height: 44px;
      max-height: 44px;
    }

    &__burger-icon {
      width: 24px;
      height: 24px;
    }

    &__logo {
      position: absolute;
      left: 30px;
      top: 23px;
      z-index: 11;
    }

    &__list_open {
      padding: 110px 0 0 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(9, 20px);
      row-gap: 30px;
      width: 100vw;
      height: 100vh;
      justify-items: center;
      flex-direction: column;
      background-color: var(--main-color-black);
      left: 0;
      top: 0;
      z-index: 10;
    }

    &__socials {
      display: grid;
      grid-template-columns: auto auto auto;
      align-items: center;
      justify-items: center;
      column-gap: 26px;
    }

    &__socials-link {
      color: var(--main-color-white);
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.whiteHeader {
  color: #fff;
}
