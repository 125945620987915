@use "../../common/styles/_mixins.scss" as *;

.SelectContained {
  border: 1px solid var(--main-color-gray);
  border-radius: 8px;
  padding: 13.5px 16px;
  position: relative;

  &__input {
    cursor: pointer;
    align-items: center;
    grid-template-columns: 1fr auto;
    font-weight: 400;
    height: 100%;
    font-size: 16px;
    line-height: 19px;
    display: grid;
  }

  &_required {
    border: 1px solid red;
  }

  &__placeholder {
    color: var(--main-color-gray);
  }

  &__content_tags {
    overflow: hidden;
    max-width: 97%;
    display: flex;
    gap: 6px;
  }

  &__content {
    display: flex;
    gap: 0 6px;
  }

  &__tag {
    display: flex;
    align-items: center;
    gap: 0 6px;
    background-color: #f3f4f6;
    border-radius: 43px;
    padding: 8px 16px;
    width: fit-content;
    white-space: nowrap;
  }

  &__tagImg {
    min-height: 18px;
    max-height: 18px;
    min-width: 18px;
    max-width: 18px;
  }

  &__options {
    background: var(--main-color-white);
    border: 1px solid rgba(26, 26, 26, 0.1);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    //padding: 20px 20px 20px 16px;
    padding: 2px 0;
    position: fixed;
    min-height: fit-content;
    max-height: 272px;
    overflow-y: auto;
    top: 110%;
    left: 0;
    width: 100%;
    z-index: 9999;
    display: grid;
    //gap: 16px 0;

    transform: translateY(-10px);
    transition: 0.25s;
    opacity: 0;
    z-index: -9999;
  }

  &__options::-webkit-scrollbar {
    height: 9px;
    width: 2px;
  }

  &__options::-webkit-scrollbar-track {
    background: var(--main-color-gray);
    border-radius: 2px;
  }

  &__options::-webkit-scrollbar-thumb {
    background-color: var(--main-color-black);
    border-radius: 2px;
  }

  &__options_hide {
    transform: translateY(0);
    opacity: 1;
    z-index: 9999;
    position: absolute;
  }

  &__option {
    cursor: pointer;
    padding: 12px 16px;
    transition: 0.25s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #797983;
  }

  &__option:hover {
    color: #797983;
    background: var(--main-color-gray);
  }

  &__option_icon {
    display: none;
  }

  &__option_checked {
    // color: #797983;
    // background: var(--main-color-gray);
  }

  &__option:hover > .SelectContained__option_icon {
    display: block;
  }

  &__option_icon_checked {
    display: block;
  }

  &__icon {
    height: 12px;
    width: 12px;
    transition: 0.25s;
  }

  &__icon_active {
    rotate: 180deg;
  }
}
