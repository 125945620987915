@use "../../common/styles/_mixins.scss" as *;

.EventCreatePage {
  // FORM
  &__form {
    max-width: 744px;
    min-width: 744px;

    padding-bottom: 140px;

    @include w1440 {
      padding-bottom: 120px;
    }

    @include w1024 {
      padding-bottom: 400px;
    }

    @include w768 {
      max-width: 700px;
      min-width: 700px;
      padding-bottom: 340px;
    }

    @include w480 {
      min-width: 100%;
      max-width: 100%;
    }
  }

  // BUTTONS
  &__buttons {
    margin-top: 50px;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 32px;

    @include w768 {
      gap: 0 15px;
    }

    @include w480 {
      margin-top: 44px;
      grid-template-columns: 1fr;
      gap: 26px 0;
    }
  }

  &__button:nth-child(1) {
    width: 235px;
  }

  &__button:nth-child(2) {
    padding: 0;
    white-space: nowrap;
  }

  &__button {
    @include w480 {
      width: 100%;
      min-width: 100%;
    }
  }

  // IMAGE
  &__image {
    position: absolute;
    max-width: 407.15px;
    max-height: 516px;
    right: 0;
    bottom: 1.5%;
    z-index: 1;

    @include w1440 {
      width: 312.46px;
      height: 396px;
    }

    @include w1024 {
      width: 312.46px;
      height: 396px;
      bottom: 0;
    }

    @include w768 {
      width: 246.18px;
      height: 312px;
    }

    @include w480 {
      width: 246.18px;
      height: 312px;
      bottom: 0;
    }
  }
}
