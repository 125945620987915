.ContactSocials {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.Contact {
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 6px;
  display: flex;
  width: fit-content;
  gap: 0 6px;
  padding: 8px;
  height: fit-content;

  &__Icon,
  &__Button {
    max-height: 18px;
    min-height: 18px;
    max-width: 18px;
    max-height: 18px;
  }

  &__Value {
    font-size: 13px;
    font-weight: 600;
    line-height: 15.83px;
  }

  &__Button {
    cursor: pointer;
  }
}
