$block-height: 33px;

.Preview {
  align-items: center;
  display: flex;
  gap: 0 6px;
  height: $block-height;

  &__Icon {
    max-height: 18px;
    min-height: 18px;
    max-width: 18px;
    min-width: 18px;
  }

  &__Text {
    color: #f5a53d;
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    max-width: 204px;
  }
}

.CardControlsButton {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.05px;
  padding: 8px 0;
  transition: 0.25s;

  &:hover {
    opacity: 0.75;
  }

  &_Light {
    background-color: #f1f1f1;
    color: #5378f0;
  }

  &_Dark {
    background-color: #333236;
    color: #ffffff;
  }
}

.EmptyState {
  height: $block-height;
}

.CardControlsSelectWrapper {
  display: flex;
  align-items: center;
  height: $block-height;
}

.CardControlsSelect {
  position: relative;
  width: fit-content;

  &__Icon {
    max-height: 18px;
    max-width: 18px;
    min-height: 18px;
    min-width: 18px;
  }

  &__Input {
    cursor: pointer;
    align-items: center;
    align-content: center;
    display: flex;
    gap: 0 6px;
  }

  &__Text {
    font-size: 13px;
    font-weight: 600;
    line-height: 15.83px;
    color: #5378f0;
    margin: 0;
  }

  &__List {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px -1px #00000014, 0px 2px 4px -1px #0000000f;
    padding: 4px 0;
    position: absolute;
    left: -7.5%;
    top: 115%;
    width: 179px;
    border: 1px solid #1a1a1a1a;
    box-sizing: content-box;
    opacity: 0;
    z-index: -10;
    transition: 0.25s;

    &_Show {
      opacity: 1;
      z-index: 9;
    }
  }

  &__Item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.49px;
    padding: 6px 8px;
    transition: 0.25s;

    &:hover {
      opacity: 0.5;
    }
  }
}
