.SelectTags {
  position: relative;

  &__input {
    cursor: pointer;
    background: var(--main-color-white);
    border: 1px solid var(--main-color-gray);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13.39px 16px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__content,
  &__placeholder {
    color: var(--main-color-dark-grey);
    font: var;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  &__placeholder {
    font-family: var(--font-roboto);
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
  }

  &__placeholder_hide {
    opacity: 0;
    position: absolute;
    z-index: -9999;
  }

  &__arrow_icon {
    transition: 0.25s;
    max-height: 12px;
    min-height: 12px;
    max-width: 12px;
    min-width: 12px;
  }

  &__arrow_icon_active {
    rotate: 180deg;
  }

  // TAGS
  &__tag {
    align-items: center;
    background: var(--main-color-gray-umber);
    border-radius: 2px;
    display: flex;
    padding: 7px 10px;
  }

  &__tag_name {
    color: var(--main-color-white);
  }

  &__tag_icon {
    max-width: 16px;
    min-width: 16px;
    max-height: 16px;
    min-height: 16px;
    margin-left: 7.5px;
  }

  &__tag_icon > * {
    fill: var(--main-color-white);
  }

  // DROPDOWN OPTIONS LIST
  &__options {
    background: var(--main-color-white);
    border: 1px solid var(--main-color-gray);
    box-shadow: 0px 0px 1px rgba(128, 128, 128, 0.15),
      0px 3px 9px rgba(65, 54, 32, 0.1);
    border-radius: 4px;
    position: absolute;
    top: 115%;
    width: 100%;
    overflow-y: auto;
    max-height: 272px;

    transform: translateY(-8px);
    transition: 0.25s;
    opacity: 0;
    z-index: -9999;
  }

  &__options {
    scrollbar-width: thin;
    scrollbar-color: rgb(0, 0, 0) var(--main-color-gray);
  }

  &__options::-webkit-scrollbar {
    height: 9px;
    width: 2px;
  }

  &__options::-webkit-scrollbar-track {
    background: var(--main-color-gray);
    border-radius: 2px;
  }

  &__options::-webkit-scrollbar-thumb {
    background-color: var(--main-color-black);
    border-radius: 2px;
  }

  &__options_hide {
    transform: translateY(0);
    opacity: 1;
    z-index: 9999;
  }

  &__not_found,
  &__option {
    cursor: pointer;
    padding: 12px 16px;
  }

  &__option {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__option:hover:not(&__option_active) {
    background-color: #f5f6f7;
  }

  &__option:hover:not(&__option_active) > &__option_icon {
    stroke: #e1e3e5;
    display: block;
  }

  &__option_icon {
    display: none;
  }

  &__option_active {
    background-color: #e2e2e2;
  }

  &__option_active > &__option_icon {
    display: block;
    fill: var(--main-color-black);
  }

  &__not_found {
    cursor: default;
  }
}
