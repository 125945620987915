@use "../../common/styles/_mixins.scss" as *;



.OrderItem {
  position: relative;
  box-shadow: 1px 1px 0px 0px #000;
  border-radius: 8px;
  border: 1px solid #000;
  padding: 16px;

  width: 100%;
  max-width: 744px;


  &:hover .Arrow {
    opacity: 1;
  }

  &__date_status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__date {
    color: #3D3D3D;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__status {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.067px;

    padding: 2px 8px;
    border-radius: 16px;
    border: 1px solid #000;
  }

  &__divider {
    height: 1px;
    background-color: #1A1A1A1A;
    margin: 16px 0;
  }

  &__table {
    border-spacing: 0 11px;
    width: 100%;

    & tbody > tr > td:nth-child(1) {
      border-right: 16px solid transparent;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-align: right;
      white-space: nowrap;
      width: 110px;
    }

    & tbody > tr > td:nth-child(2) {
      color: #3D3D3D;
      max-width: 80%;
    }
  }

  &__address {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  &__url {
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-skip-ink: auto;

    &:hover {
      color: black;
    }

    &:not(:last-child)::after {
      content: ",";
      margin-right: 4px;
      text-decoration:none;
    }
  }

  &__link_to_order {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    right: 0;

    padding: 16px;

    @include w480 {
      padding: 8px 16px;
    }
  }
}

.Arrow {
  opacity: 0;

  width: 60px;
  height: 15px;

  @include w480 {
    width: 21px;
    height: 16px;
  }
}