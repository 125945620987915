.Stepper {
  width: 373px;
  text-align: center;
  font-size: 18px;
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-left: 88px;

  &__field {
    margin-top: 20px;
    background-color: #D9D9D9;
    border-radius: 4px;
    height: 4px;
  }

  &__fill {
    background-color: #000000;
    border-radius: 4px;
    height: 100%;
    z-index: 1;
  }
}

@media (max-width: 1024px) {
  .Stepper {
    width: 301px;
    margin-left: 50px;

    &__field {
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .Stepper {
    width: 250px;
    margin-left: 40px;

    &__field {
      margin-top: 16px;
    }
  }
}

@media (max-width: 480px) {
  .Stepper {
    width: 200px;
    margin-left: 0px;
    margin-top: 40px;
    text-align: start;

    &__field {
      margin-top: 16px;
    }
  }
}