$default-btn-color: #f3f4f6;
$active-btn-color: #333236;

.Navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-proxima);
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

.NavigationButton {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: $default-btn-color;
  height: 44px;
  width: 44px;

  &:disabled {
    opacity: 0.5;
  }

  &:not(:disabled):hover {
    background-color: $active-btn-color;

    & > * > * {
      stroke: white;
    }
  }

  &,
  &__Icon > * {
    transition: 0.25s;
  }

  &__Icon > * {
    stroke: $active-btn-color;
  }
}
