@use "../../common/styles/_mixins.scss" as *;

.EventCover {
    align-items: center;
    display: grid;
    gap: 8px 0;
    justify-content: center;
    justify-items: center;
    border: 1px solid #000000;
    border-radius: 4px;
    padding: 24px 0;
    max-width: 299px;
    position: relative;
    min-height: 136px;
    max-height: 136px;
    transition: .25s;

    @include w480 {
        max-width: 100%;
    }

    &__image {
        max-height: 64px;
        min-height: 64px;
        max-width: 64px;
        min-width: 64px;
    }

    &__image_uploaded {
        height: 100%;
        border-radius: 3px;
        position: absolute;
        left: 0;
        top: 0;
    }

    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
    }

    &__input {
        cursor: pointer;
        position: absolute;
        z-index: 1;
        opacity: 0;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    &__button_delete {
        align-items: center;
        background-color: var(--main-color-white);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 5px;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    &__button_delete:hover {
        background-color: var(--main-color-gray);
    }

    &__button_delete_icon {
        max-height: 8.5px;
        min-height: 8.5px;
        max-width: 8.5px;
        min-width: 8.5px;
    }
}