@use "src/common/styles/_mixins.scss" as *;

.PollHeader {
  &__Title {
    color: #333236;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.98px;

    @include iftablet {
      font-size: 26px;
      line-height: 31.67px;
    }

    @include ifmobile {
      font-size: 20px;
      line-height: 24.36px;
    }
  }

  &__Text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    margin-top: 6px;
    color: #797983;
    // &_Default {
    // }

    // &_Bold {
    //   font-size: 21px;
    //   font-weight: 600;
    //   line-height: 27.3px;
    //   color: #000000;
    //   margin-top: 30px;

    //   @include iflaptop {
    //     font-size: 18px;
    //     font-weight: 600;
    //     line-height: 23.4px;
    //   }

    //   @include ifmobile {
    //     margin-top: 22px;
    //   }
    // }
  }
}
