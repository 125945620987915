@use "../../common/styles/_mixins.scss" as *;

.TestButtons {
  position: fixed;
  top: 20vh;
  right: 5vw;
  width: 120px;

  flex-direction: column;
  gap: 20px;

  z-index: 99999;
  font-size: 12px;

  & button {
    font-size: 14px;
    height: 60px;
    width: 60px;
    padding: 12px 12px;
  }

  display: none;

  &__showbutton {
    position: absolute;
    bottom: 0;
    left: 10vw;
    width: 20px;
    height: 20px;
    background-color: black;
    border: 1px solid white;
    z-index: 999999;
  }
}

.TestButtons_active {
  display: flex;
}

.Account {
  display: grid;
  gap: 30px;
  margin: 0 auto;

  max-width: 744px;
  padding: 0 0 233px 0;

  @include iflaptop {
    padding: 0 0 236px 0;
  }

  @include iftablet {
    padding: 0 0 150px 0;
  }

  @include ifmobile {
    padding: 0 0 395px 0;
  }

  &__title {
    line-height: normal;
    font-size: 32px;
    white-space: nowrap;

    @include w480 {
      font-size: 28px;
      line-height: normal;
    }
  }

  &__blocks {
    display: grid;
    gap: 30px;
  }

  &__space {
    height: 5vw;
    @include w480 {
      height: 100vw;
    }
  }

  &__image {
    position: absolute;
    max-width: 394px;
    max-height: 483px;
    right: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;

    @include iflaptop {
      display: none;
    }

    @include ifmobile {
      display: block;
      max-width: 238px;
      max-height: 286px;
    }
  }
}

.Poll {
  &__text {
    white-space: nowrap;
  }

  &__input {
    height: 58px;
    min-height: 58px;
    max-height: 58px;

    @include w480 {
      height: 53px;
    }
  }

  &__grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    @include w480 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__grid_box {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 10px;

    @include w480 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 16px;
    }
  }

  &__grid_button {
    display: flex;
    justify-self: flex-end;
    align-items: center;
  }

  &__hide_text {
    @include w480 {
      display: none;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__notify {
    display: flex;
    gap: 20px;

    &__style {
      font-size: 16px;
      line-height: 20.8px;
    }

    @include w480 {
      flex-direction: column;
    }
  }
}

.AccountForm {
  display: flex;
  justify-content: space-between;
  gap: 0 30px;

  @include ifmobile {
    gap: 30px;
  }

  @include ifmobile {
    flex-flow: column;
  }

  &__AvatarCircle {
    cursor: pointer;
    position: relative;
    max-width: 180px;
    min-width: 180px;
    max-height: 180px;
    min-height: 180px;
    border-radius: 50%;

    @include ifmobile {
      max-width: 150px;
      min-width: 150px;
      max-height: 150px;
      min-height: 150px;
    }

    &_Fill {
      &::before {
        content: "";
        background-color: #00000080;
        display: none;
        color: white;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        z-index: 9;
        // transition: 0.25s;
      }

      &:hover::before {
        display: flex;
      }
    }

    &_Empty {
      background-color: #00000080;

      &::before {
        content: "Загрузить фото";
        font-size: 16px;
        color: white;
        position: absolute;
        display: block;
        width: fit-content;
        height: fit-content;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  &__AvatarCircle_Fill:hover &__AvatarText {
    display: block;
  }

  &__AvatarText {
    // transition: 0.25s;
    position: absolute;
    display: block;
    width: fit-content;
    height: fit-content;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    display: none;
    bottom: 0;
    text-align: center;
    color: white;
    z-index: 9999;
  }

  &__AvatarInput {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  &__AvatarImage {
    object-fit: cover;
  }

  &__Fields {
    display: grid;
    gap: 20px;
    width: 100%;

    & > *:nth-child(1),
    & > *:nth-child(3) {
      grid-column: 1 / 2;

      @include ifmobile {
        grid-column: 1 / 3;
      }
    }

    & > *:nth-child(2),
    & > *:nth-child(4) {
      grid-column: 2 / 3;

      @include ifmobile {
        grid-column: 1 / 3;
      }
    }

    & > *:nth-child(5),
    & > *:nth-child(6) {
      grid-column: 1 / 3;
    }
  }
}
