@use "src/common/styles/_mixins.scss" as *;

.ChatMessages {
  padding: 10px 0 0 0;
  // position: relative;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  --input-height: 0px;

  height: calc(100vh - 40px - 45.5px - 10px - 30px - 167.81px - 34px);
  min-height: 437px;

  @include iflaptop {
    height: calc(100vh - 90px - 32px - 30px - 153.8px);
  }

  @include iftablet {
    height: calc(100vh - 73px - 42px - 30px - 153.8px);
  }

  @include ifmobile {
    padding: 12px 0 24px 0;
    min-height: auto;
    max-height: auto;
    height: calc(100vh - 60px - var(--input-height) + 12px);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__List {
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding: 34px 0 0 0;
    margin-top: auto;
  }

  &__ListDate {
    opacity: 0.7;
    color: #333236;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.6px;
  }

  &__UserMessage {
    margin-left: auto;
  }

  &__Loader {
    display: flex;
    justify-content: center;
    opacity: 0;
    &_Show {
      opacity: 1;
    }
  }

  &__Placeholder {
    // position: absolute;
    // width: auto;
    // left: 0;
    // right: 0;
    // top: 57px;
    // margin: 0 auto;

    @include ifmobile {
      margin: unset;
    }
  }
}

.Placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px 0;

  @include ifmobile {
    text-align: left;
    align-items: flex-start;
  }

  &__Icon {
    @include ifmobile {
      min-height: 45.5px;
      min-width: 45.5px;
      max-height: 45.5px;
      max-width: 45.5px;
      margin-right: auto;
    }
  }

  &__Title {
    font-size: 36px;
    font-weight: 700;
    line-height: 50.4px;

    @include iflaptop {
      font-size: 30px;
      line-height: 42px;
    }

    @include ifmobile {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__Text {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    max-width: 522px;

    @include iflaptop {
      font-size: 16px;
      line-height: 22.4px;
    }
  }
}
