@use "src/common/styles/_mixins.scss" as *;

.ChatBot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px 0;
  width: 572px;
  margin: 0 auto;

  @include ifmobile {
    width: auto;
    max-width: 572px;
  }

  &_Full {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
}

.ChatBotPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__Title {
    font-size: 36px;
    font-weight: 700;
    line-height: 50.4px;

    @include iflaptop {
      font-size: 30px;
      line-height: 42px;
    }

    @include ifmobile {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__Text {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    max-width: 522px;

    @include iflaptop {
      font-size: 16px;
      line-height: 22.4px;
    }
  }

  &__Button {
    margin-top: 30px;
  }
}
