@use "src/common/styles/_mixins.scss" as *;

.Checkbox {
  align-items: center;
  border: 1px solid #d4d3d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 26px;
  max-height: 26px;
  min-width: 26px;
  max-width: 26px;

  @include ifmobile {
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
  }

  &_Error {
    border-color: var(--main-color-red);
  }

  &__Input {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &__Icon {
    height: 14px;
    width: 14px;

    @include ifmobile {
      height: 12px;
      width: 12px;
    }
  }
}
