@use "src/common/styles/_mixins.scss" as *;

%font {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.ContactCommentPreview {
  @extend %font;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 31px;
  white-space: nowrap;
  // width: 300px;
  color: #797983;
}

.ContactComment {
  @extend %font;
  border: none;
  padding: 7.5px 0;

  &:focus {
    outline: none;
  }

  @include ifmobile {
    min-height: auto !important;
  }
}
