@use "src/common/styles/_mixins.scss" as *;

.Cards {
  &:not(.Cards_Empty) {
    display: grid;
    grid-template-columns: repeat(3, 390px);
    grid-auto-rows: 1fr;
    gap: 35px;

    @include iflaptop {
      grid-template-columns: repeat(2, minmax(0, 602.5px));
    }

    @include ifmobile {
      grid-template-columns: repeat(1, minmax(0, 747px));
      grid-auto-rows: unset;
      gap: 19px 0;
    }
  }

  padding: 35px 0;

  @include iflaptop {
    padding: 32px 0;
  }

  @include ifmobile {
    gap: 19px 0;
  }

  &_Empty {
    align-items: center;
    justify-content: center;
  }
}
