@use "../../common/styles/mixins" as *;

.nav-auth__account-link {
  @include w480 {
    display: none;
  }
}

.nav-auth__account-link:nth-of-type(1) {
  margin-right: 15px;
}

.nav-auth__account-button {
  padding: calc(12px / 1920px * 100vw) calc(26px / 1920px * 100vw);
  font-size: calc(18px / 1920px * 100vw);
  font-family: var(--font-roboto) !important;

  @include w768 {
    padding: 0 !important;
    border: 0;
    background: initial;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 12px;
  }
}

.nav-auth__account-button_account {
  padding: initial;
  font-size: 18px;
  font-family: var(--font-proxima) !important;
  height: 48px;
  padding-left: 26px;
  padding-right: 26px;

  @include w480 {
    display: none;
  }
}

.nav-auth__account-menu {
  @include w1024 {
    margin-right: 10px;
    display: none;
  }
}
