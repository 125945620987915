@use "../../../common/styles/mixins" as *;

.MainContent {
  &__AccordeonTextContainer {
    height: auto;
    overflow: hidden;
    transition: height 0.4s ease-in-out;
  }

  &__AccordeonTextContainer__Open {
    height: auto;
  }

  &__AccordeonText {
    font-size: 16px;
    line-height: 150%;

    color: #1a1a1a;
  }

  &__Button {
    cursor: pointer;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 37px;

    background: #ececec;
    border-radius: 8px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: #303030;

    border: none;
    outline: none;

    margin-top: 8px;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.7;
    }
  }
}
