@use "../../common/styles/_mixins.scss" as *;

.LayoutAccount {
  display: grid;
  justify-content: center;
  // grid-template-columns: 1fr;
  gap: 32.5px 0;

  @include w480 {
    gap: 34px 0;
  }

  &__header {
    display: grid;
    gap: 21.5px 0;
  }

  &__button_go_back {
    max-width: 61px;
  }
}
