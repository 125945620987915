.base-checkbox-item {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid var(--main-color-gray);
  border-radius: 4px;
  padding: 9px 16px;
  max-height: 58px;
  min-height: 58px;
  display: grid;

  &_required {
    border: 1px solid var(--main-color-red);
  }

  &_disabled {
    background-color: #F3F4F6;
  }

  @media (max-width: 480px) {
    max-height: 48px;
    min-height: 48px;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--main-color-dark-grey);
  }

  &__checkbox {
    opacity: 0;
    z-index: 1;
  }

  &__input {
    width: calc(100% + 32px);
    height: calc(100% + 18px);
    left: 0;
    top: 0;
    position: absolute;
    cursor: pointer;
    margin: -9px -16px;
  }

  &__icon {
    border-radius: 50%;
    background: #f5f5f5;
    border: 1px solid var(--main-color-gray);
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text_checked {
    color: var(--maon-color-black);
  }

  &__icon_checked,
  &_checked {
    border: 1px solid var(--main-color-black);
  }

  &__textarea > * {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    width: 100%;
    height: 119px;
    border: 1px solid var(--main-color-gray);
    border-radius: 4px;
    padding: 10px;
    line-height: 19px;
    margin: 15px 0 0 0;
    color: var(--maon-color-black);
    resize: none;
  }

  &__textarea > *:focus {
    outline: none;
  }
}

@media (max-width: 480px) {
  .base-checkbox-item {
    &__text {
      font-size: 14px;
    }

    &__icon {
      max-width: 20px;
      min-width: 20px;
      max-height: 20px;
      min-height: 20px;

      & svg {
        width: 12px;
        height: 8.5px;
      }
    }
  }
}