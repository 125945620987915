@use "./_mixins.scss" as *;

%font-w900-fs32-roboto {
  font: 700 32px "Roboto", sans-serif;
}

%font-w700-fs24-roboto {
  font: 700 24px "Roboto", sans-serif;
}

%font-w700-fs20-roboto {
  font: 700 20px "Roboto", sans-serif;
}

%font-w400-fs32-roboto {
  font: 400 32px "Roboto", sans-serif;
}

%font-w400-fs24-roboto {
  font: 400 24px "Roboto", sans-serif;
}

// Account
%account-content-title {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;

  @include w480 {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
  }
}

%header {
  height: 40px;
  padding: 0 50px;
  margin: 40px 0 0 0;

  @include iflaptop {
    height: 50px;
    padding: 0 60px;
  }

  @include iftablet {
    height: 33px;
    padding: 0 34px;
  }

  @include ifmobile {
    height: 60px;
    padding: 0 14px;
    margin-top: 0;
  }
}
