@use "../../common/styles/_mixins.scss" as *;
@use "../../common/styles/_extends.scss" as *;

.SkeletonPreview {
  min-height: 521.52px;
  max-height: 521.52px;
  max-width: 387px;
  min-width: 387px;

  @include w480 {
    max-width: 336px / 360px * 100vw;
    min-width: 336px / 360px * 100vw;
  }
}

.EventsPage {
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include w768 {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__tabs_list {
    @include w768 {
      margin-top: 46px;
    }

    @include w480 {
      margin-top: 24px;
      width: 100%;
    }
  }

  &__filters {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto 1fr;

    @include w480 {
      grid-template-columns: auto 1fr;
      gap: 22px 0;
    }
  }

  &__date_filters {
    margin-top: 42px;

    @include w768 {
      margin-top: 32px;
    }
  }

  &__category_filters {
    margin-top: 16px;

    @include w768 {
      margin-top: 12px;
    }
  }

  &__events {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 42px;
    padding-bottom: 10px;
    justify-items: center;

    @include w1024 {
      grid-template-columns: repeat(2, 1fr);
      max-width: 794px;
    }

    @include w768 {
      max-width: 100%;
      justify-items: center;
      margin-top: 32px;
    }

    @include w480 {
      grid-template-columns: 1fr;
      margin-top: 46px;
    }
  }

  // Footer
  &__footer {
    display: grid;
    row-gap: 30px;
    margin-top: 48px;

    @include w480 {
      row-gap: 40px;
      margin-top: 40px;
    }
  }

  &__button_upload_more,
  &__button_create {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    @include w480 {
      font-size: 16px;
      line-height: 19px;
      width: 100%;
    }
  }

  &__button_upload_more {
    margin-top: 30px;
    @include w480 {
      margin-top: 40px;
    }
  }

  &__button_create {
    line-height: 25.2px;

    @include w768 {
      align-self: flex-end !important;
    }
  }
}

.TabContent {
  &__text {
    font-family: var(--font-proxima);
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    @include w768 {
      font-size: 18px;
      line-height: 21px;
    }

    @include w480 {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__content {
    justify-content: center;
    align-items: center;
    display: flex;

    & > *:nth-child(2) {
      margin-left: 10px;
    }
  }
}

.EventDateFilters,
.EventCategoryFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 12px;

  @include w480 {
    gap: 12px;
  }
}

.EventDateFilters {
  &__calendar:hover > &__icon > * {
    stroke: var(--main-color-white);
  }

  &__datepicker {
    left: 0;
    top: -40%;
    height: 38.19px;
    transition: 0.25s;
    border: none;
  }

  &__datepicker:hover > *:nth-child(1),
  &__datepicker_active > *:nth-child(1) {
    background: var(--main-color-graphite-black) !important;
  }

  //&__datepicker:hover > *:nth-child(1) > *:nth-child(3) > * > *,
  //&__datepicker_active > *:nth-child(1) > *:nth-child(3) > * > * {
  //  stroke: var(--main-color-white);
  //}

  &__datepicker:hover > * > * > * > path,
  &__datepicker_active > * path {
    stroke: var(--main-color-white);
  }

  &__datepicker > *:nth-child(1) {
    padding: 0;
    display: flex;
    justify-content: center;
    position: relative;
    background: var(--main-color-white);
    border: 1px solid var(--main-color-graphite-black);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    min-height: 38.19px;
    max-height: 38.19px;
    width: 54px;
  }

  &__datepicker > *:nth-child(1) > *:nth-child(1) {
    display: none;
  }

  &__datepicker > *:nth-child(2) > * {
    width: 324px;
    min-width: 324px;
  }

  &__datepicker > *:nth-child(1) > *:nth-child(3) {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
