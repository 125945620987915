.AgeSelector {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}

.AgeSelectorDisabled {
  .Mui-disabled {
    background-color: #bdbdbd !important;
    & > * {
      color: #bdbdbd !important;
    }
  }

  & > *:nth-child(1) {
    color: #bdbdbd !important;
  }
}
