.InputPassword {
  &__Field {
    position: relative;
  }

  &__Input {
    padding-right: 64px;
  }

  &__Toggle {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    right: 21px;
    top: 0;
    margin: auto 0;
  }

  &__Validation {
    align-items: center;
    display: flex;
    gap: 0 8px;
    margin: 8px 0 0 0;
  }
}

.Validator {
  align-items: center;
  color: #797983;
  display: flex;
  gap: 0 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;

  &__Icon {
  }

  &__Text {
  }
}
