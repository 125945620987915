@use "src/common/styles/_mixins.scss" as *;

.Contacts {
  font-family: var(--font-proxima);

  &__Title {
    font-size: 32px;
    font-weight: 600;
    line-height: 38.98px;
    margin-bottom: 26px;

    @include ifmobile {
      font-size: 26px;
      font-weight: 600;
      line-height: 31.67px;
      margin-bottom: 18px;
    }
  }
}
