@use "../../common/styles/mixins" as *;

.CreatogEventCredsModal {
  width: 469px;
  padding: 32px;

  @include w480 {
    width: 340px;
    padding: 24px 18px;
  }

  .title {
    line-height: 44px;
    color: #1a1a1a;
    font-size: 24px;

    @include w480 {
      line-height: 26px;

      font-size: 21px;
    }
  }

  .note {
    line-height: 22px;
    margin-top: 6px;
    color: #797983;
    font-size: 18px;

    @include w480 {
      line-height: 24px;

      font-size: 16px;
    }
  }

  .inputContainer {
    margin-top: 32px;
    display: grid;
    gap: 12px;
    @include w480 {
      margin-top: 28px;
    }
  }

  .buttonContainer {
    margin-top: 32px;
    padding: 0 17.5px;

    display: flex;
    gap: 31px;
    @include w480 {
      margin-top: 28px;
      padding: 0;
    }
  }

  .cross {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;

    cursor: pointer;

    background: none;
    outline: none;
    border: none;

    @include w480 {
      top: 17px;
      right: 5px;
    }
    &:hover {
      opacity: 1;
    }

    &:before,
    &:after {
      position: absolute;
      top: 20%;
      left: 45%;
      content: " ";
      height: 20px;
      width: 2px;
      background-color: #333;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
