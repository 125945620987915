@use "../../../common/styles/_mixins.scss" as *;

.base-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(1, 1, 1, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.base-modal {
  background: var(--main-color-white);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 4px 6px -1px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border-radius: 10px;
  padding: 24px 32px 32px 32px;
  position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: fit-content;

  &:focus-visible {
    outline: unset;
  }

  @include w768 {
    //width: 700px / 768px * 100vw;
    width: fit-content;
  }

  @include w480 {
    padding: 20px;
    //width: 450px / 480px * 100vw;
    width: fit-content;
  }

  &__close-icon {
    cursor: pointer;
    max-height: 14.4px;
    min-height: 14.4px;
    max-width: 14.4px;
    min-width: 14.4px;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 99999;
    border-radius: 50%;

    @include w480 {
      top: 26px;
    }
  }

  &__title {
    font-family: var(--font-proxima);
    font-weight: 400;
    font-size: 26px;
    line-height: 28px;

    @include w480 {
      font-size: 20px;
    }
  }
}
