.base-score {
  align-items: center;
  display: flex;

  &__list {
    align-items: center;
    display: flex;
  }

  &__item {
    cursor: pointer;
    margin-right: 13px;
    position: relative;
    background: center / cover no-repeat
      url('../../../common/svg/svg-star-icon.svg');
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
  }

  &__item_active {
    background-image: url('../../../common/svg/svg-star-icon-full.svg');
  }

  &__counter {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--main-color-dark-grey);
  }
}
