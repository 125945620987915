.Tabs {
  align-items: center;
  display: flex;

  &__item {
    cursor: pointer;
    color: rgba(26, 26, 26, 0.7);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 4px;
    transition: 0.4s;
    position: relative;

    &::after {
      content: "";
      transition: 0.4s;
      width: 0;
    }
  }

  &__item:hover {
    color: var(--main-color-graphite-black);
  }

  &__item:nth-child(1n + 2) {
    margin-left: 42px;
  }

  &__item_active {
    color: var(--main-color-graphite-black) !important;
    font-weight: 400 !important;

    &::after {
      content: "";
      background-color: var(--main-color-graphite-black);
      bottom: 0;
      position: absolute;
      left: 0;
      height: 2px;
      width: 100%;
    }
  }
}
