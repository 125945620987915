.BaseInputPassword {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  height: 58px;
  border: 1px solid var(--main-color-gray);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  box-sizing: border-box;
  //padding: 0 3px;
  user-select: none;
  cursor: text;

  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &_required {
    border: 1px solid var(--main-color-red);
  }
}

.input-password {
  width: 85%;
  max-width: 85%;
  padding: 0 18px;
  height: 100%;
  //position: absolute;
  border: 0;
  font-size: 16px;
  font-family: var(--font-proxima);


  &_required::placeholder {
    color: var(--main-color-red);
  }
}

.input-password:focus {
  color: var(--main-color-black);
  outline: 0;
}

.input-password::-webkit-input-placeholder {
  color: var(--main-color-dark-grey);
}

.input-password::placeholder {
  color: var(--main-color-dark-grey);
}


@media (max-width: 480px) {
  .BaseInputPassword {
    height: 48px;
  }
}

.show-input-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 45px;
  padding: 0 21px;
  background-color: inherit;
  border: none;

  cursor: pointer;
  user-select: none;
}