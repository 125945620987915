.footer_home {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 10px;
  z-index: 2;
  &::after {
    position: absolute;
    content: "";
    background: rgb(244, 244, 244);
    top: 0%;
    width: 150%;
    height: 100%;
    border-radius: 100% 100% 0 0;
    z-index: 1;
    object-fit: cover;
  }
  &__main_wrapper {
    position: relative;
    z-index: 2;
    transform: translate(-5%, -5%);
  }
  &__wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__semechka_wrapper_first::before {
    content: "";
    position: absolute;
    background: black;
    bottom: -100%;
    left: -15%;
    width: 14px;
    height: 3px;
    border-radius: 50px/10px;
    display: block; /*adjust as needed*/
  }
  &__semechka_wrapper_second::before {
    content: "";
    position: absolute;
    background: black;
    bottom: -100%;
    left: 25%;
    width: 22px;
    height: 5px;
    border-radius: 50px/10px;
    display: block; /*adjust as needed*/
  }
  &__text {
    width: 396.49px;
    transform: rotate(4.13deg);
    padding: 24px;
    border-radius: 24px;
    box-shadow: 2px 2px 0px 0px rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    font-weight: 600;
  }
  &__right &__text {
    position: relative;
    width: 235px;
    transform: rotate(-2.07deg);
    &::after {
      top: 99%;
      right: 60%;
      position: absolute;
      content: url("../../common/images/flash2.png");
    }
  }
  &__left &__text {
    &::after {
      top: 99%;
      right: 5%;
      position: absolute;
      content: url("../../common/images/flash.png");
    }
  }
  &__image_wrapper {
    margin-right: 129px;
    margin-left: 52px;
    position: relative;
    &::after {
      position: absolute;
      top: 25%;
      right: -25%;
      bottom: 0;
      content: url("../../common//images/svg/semechka.svg");
      display: block; /*adjust as needed*/
    }
  }
  &__bottom_wrapper {
    z-index: 5;
    width: 100%;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
  }
  &__copyright,
  &__number {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
  &__semechka_wrapper_first {
    position: absolute;
    top: 85%;
    left: -30%;
  }
  &__semechka_wrapper_second {
    position: absolute;
    top: 92%;
    left: 110%;
  }
  &__semechka_first,
  &__semechka_second {
    width: auto !important;
  }

  @media screen and (max-width: 1622px) {
    padding-bottom: 28px;
    &__text {
      width: 300px;
      padding: 12px;
      font-size: 12px;
    }
    &__image {
      width: 148px;
      height: 195px;
    }
    &__main_wrapper {
      transform: translate(-5%, -5%);
    }
    &__right &__text {
      position: relative;
      width: 160px;
      transform: rotate(-2.07deg);
      &::after {
        top: 54%;
        right: 40%;
        transform: scale(0.4);
        position: absolute;
      }
    }

    &__image_wrapper {
      &::before {
        transform: translateX(5px);
        left: 150%;
      }
    }
    &__semechka_wrapper_first::before {
      bottom: -55%;
      left: -15%;
    }
    &__semechka_wrapper_second::before {
      bottom: -60%;
      left: 25%;
    }
    &__left &__text {
      &::after {
        top: 98%;
        right: 5%;
        position: absolute;
      }
    }
    &__left &__text {
      &::before {
        bottom: -110%;
        left: 95%;
      }
    }
    &__copyright,
    &__number {
      font-size: 14px;
    }

    &__semechka_wrapper_first {
      top: 90%;
      left: -45%;
    }
    &__semechka_wrapper_second {
      top: 85%;
      left: 157%;
      transform: scale(0.8);
    }
    &__bottom_wrapper {
      width: 100%;
      max-width: 990px;
    }
  }
  @media screen and (max-width: 960px) {
    padding-bottom: 30px;
    &__image_wrapper {
      margin-left: 28px;
      margin-right: 42px;
    }
    &__text {
      width: 200px;
    }
    &__copyright,
    &__number {
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
    }
    &__left &__text {
      &::after {
        top: 99%;
        right: 5%;
      }
    }
    &__left {
      transform: translateY(-20%);
    }
    &__right &__text::after {
      right: 30%;
    }
    &__semechka_wrapper_first::after {
      left: 31%;
    }
    &__semechka_wrapper_second::before {
      bottom: -55%;
      transform: translateX(5px);
    }
    &__image_wrapper::before {
      transform: scale(0.7);
      left: 105%;
    }
    &__semechka_wrapper_first {
      top: 90%;
      left: -25%;
      transform: scale(0.8);
    }
    &__semechka_wrapper_second {
      top: 85%;
      left: 100%;
      transform: scale(0.8);
    }
    &::after {
      border-radius: 50% 50% 0 0;
    }
    &__bottom_wrapper {
      width: 100%;
      max-width: 750px;
    }
  }
  @media screen and (max-width: 660px) {
    padding-bottom: 30px;
    &::after {
      border-radius: 10% 10% 0 0;
      width: 105%;
      height: 100%;
    }
    &__image {
      width: 120px;
      height: 160px;
    }
    &__image_wrapper {
      margin-right: 0;
      margin-left: 0;
    }
    &__main_wrapper {
      transform: translate(0%, -5%);
    }
    &__wrapper {
      align-items: normal;
    }
    &__text {
      padding: 9px;
      font-size: 9px;
      width: 138px;
    }
    &__left {
      transform: translateX(10%);
    }
    &__right {
      z-index: 1;
      transform: translateX(-15%);
    }
    &__left &__text::after {
      transform: scale(0.8);
      top: 93%;
      right: 30%;
    }
    &__left &__text::before {
      bottom: -100%;
      left: 65%;
    }
    &__left &__text {
      transform: rotate(4.13deg);
    }
    &__semechka_second::after {
      bottom: 20%;
      left: 30%;
    }
    &__right &__text {
      transform: translateY(70%) rotate(-2.07deg);
      width: 100px;
      border-radius: 15px;

      &::after {
        content: url("../../common/images/flash-mobile.png");
        transform: scale(1);
        top: 98%;
        right: 40%;
      }
    }
    &__image_wrapper::after {
      left: 90%;
      top: 10%;
      z-index: 2;
    }
    &__image_wrapper::before {
      top: 70%;
      left: 90%;
      z-index: 2;
    }
    &__semechka_first::before {
      left: 70%;
      top: 85%;
      transform: translateX(0);
    }
    &__semechka_wrapper_first {
      top: 60%;
      left: -30%;
      transform: scale(0.8);
    }
    &__semechka_wrapper_second {
      transform: scale(0.6);
      top: 75%;
      left: 90%;
    }
    &__bottom_wrapper {
      padding-left: 18px;
      padding-right: 18px;
      width: 100%;
      max-width: 750px;
    }
  }
}
