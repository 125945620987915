.AccountOrders {
  display: grid;
  gap: 30px;

  &__full {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  &__empty {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    color: #666;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}