@use "src/common/styles/_mixins.scss" as *;

.PollLayout {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  &__Form {
    animation: 0.4s ease-in 0s showanim;
    margin: 113px 0 0 0;
    padding: 0 0 39px 0;
    display: flex;
    flex-flow: column;
    gap: 28px 0;

    @include iflaptop {
      margin: 80px 0 0 0;
    }

    @include iftablet {
      margin: 118px 0 0 0;
    }

    @include ifmobile {
      padding: 0 0 81px 0;
      gap: 22px 0;
      margin: 0;
    }
  }

  &__Navigation {
    @include ifmobile {
      background-color: #ffffff;
      border-top: 1px solid #f3f4f6;
      bottom: 0;
      position: fixed;
      left: 0;
      width: 100%;
      padding: 8px 14px;
      z-index: 5;
    }
  }
}

@keyframes showanim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
