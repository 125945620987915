.base-loader {
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: fit-content;

  &__item {
    max-height: 30px;
    min-height: 30px;
    min-width: 30px;
    max-width: 30px;
    background: center / contain no-repeat
      url('../../../common/images/image-single-black-seed.webp');
  }

  &__item:nth-of-type(1),
  &__item:nth-of-type(2),
  &__item:nth-of-type(3) {
    animation-timing-function: ease-in-out;
    animation-name: slidein1;
    animation-iteration-count: infinite;
    animation-duration: 1s;
  }

  &__item:nth-of-type(2) {
    animation-delay: 0.25s;
  }

  &__item:nth-of-type(3) {
    animation-delay: 0.5s;
  }

  &_center {
    position: absolute;
    left: calc(50vw - 50px);
    top: calc(50vh - 15px);
  }
}

@keyframes slidein1 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}
