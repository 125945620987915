.DialogOverflow {
  align-items: center;
  border: none;
  background-color: #00000040;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  outline: none;
}

.Dialog {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px 0px #0000000f;
  padding: 40px;
  width: fit-content;
}
