@use "../../../common/styles/mixins" as *;

.InputDateWithCalendar {
  position: relative;
  height: 58px;

  @include w480 {
    height: 48px;
  }
}

.InputBox {
  display: flex;
  align-items: center;
  position: absolute;

  background: rgba(255, 255, 255, 0.8);
  border: 1px solid var(--main-color-gray);
  border-radius: 4px;
  // font-size: 16px;
  font-family: var(--font-proxima);
  // font-weight: 400;

  max-width: 100%;

  height: 100%;
  width: 100%;

  &:focus-within {
    border-color: black;
  }

  &_required {
    border: 1px solid var(--main-color-red);
  }

  &__input,
  &__input::placeholder {
    font-family: var(--font-proxima);

    font-size: 16px;
    font-weight: 400;
    line-height: 19.49px;

    @include ifmobile {
      font-size: 14px;
    }
  }

  &__input {
    border: none;
    border-radius: 4px;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0 16px;

    &::placeholder {
      font-weight: 400;
      color: #797983;
    }
  }

  &__icon {
    cursor: pointer;
    padding: 0 20px;
    @include w480 {
      padding: 0 10px;
    }
  }
}

.Calendar {
  position: absolute;
  z-index: 999999;
  top: 90%;
  right: 0;
  width: fit-content;

  display: none;

  &__show {
    display: block;
  }
}
