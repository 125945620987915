@import "../src/common/fonts/ProximaNova/index.scss";

:root {
  --main-color-black: #000000;
  --main-color-white: #ffffff;
  --main-color-gray: #d4d3d9;
  --main-color-red: #e90000;
  --main-color-so-dark-grey: #3d3d3d;
  --main-color-dark-grey: #797983;
  --main-color-light-grey: #7b7b7b;
  --main-color-so-light-grey: #f7f7f7;
  --main-color-gray-umber: #303030;
  --main-color-platinum-gray: #767676;
  --main-color-black-brown: #222426;
  --main-color-light-sand: #fdffa8;
  --main-color-graphite-black: #1a1a1a;

  --font-roboto: "Roboto", -apple-system, BlinkMacSystemFont, sans-serif;
  --font-proxima: "Proxima Nova", sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(0, 0, 0) var(--main-color-gray);
}

*::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: var(--main-color-gray);
  border-radius: 9px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--main-color-black);
  border-radius: 9px;
}

html,
body {
  box-sizing: border-box;
}

body {
  margin: 0;
  // font-family: var(--font-roboto);
  font-family: var(--font-proxima);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //* Rules for the mobile screen, make a margin bottom when keyboard is open
  margin-bottom: calc(env(keyboard-inset-height) / 3);
}

h2,
h3 {
  margin: 0;
  word-break: keep-all;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}
