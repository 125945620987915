@use "../../common/styles/mixins" as *;

.ImageUploader {
  border: 1px dashed var(--main-color-gray);
  border-radius: 8px;
  background-color: white;
  position: relative;
  cursor: pointer;
  padding: 30px 21px;
  width: 390px;
  height: 240px;
  transition: 0.25s;
  display: grid;

  @include w480 {
    height: 208px;
    width: 338px;
  }

  &--isBig {
    width: 718px;

    @include w768 {
      width: 700px;
    }
    @include w480 {
      width: 100%;
      height: 189px;
    }
  }

  &_drop,
  &:hover {
    border: 1px dashed var(--main-color-graphite-black);
  }

  &_drop,
  &:hover > * > * {
    color: var(--main-color-graphite-black);
  }

  &__inner {
    // gap: 20px 0;
    display: grid;
    justify-items: center;
  }

  &__content {
    display: grid;

    justify-items: center;
    margin-top: 10px;

    @include w480 {
      align-items: center;
      border: 1px solid #d1d5db;
      grid-template-columns: auto 1fr;
      gap: 0 12px;
      padding: 7px 20px;
      /* shadow/shadow-sm [light] */

      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 24px;
    text-align: center;

    color: rgba(26, 26, 26, 0.7);

    margin: 16px 0 0;
  }

  &__format {
    color: rgba(26, 26, 26, 0.4);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    // line-height: 20px;
    margin-top: 13px;
  }

  &__note {
    font-size: 12px;
    line-height: 18px;

    text-align: center;

    color: rgba(26, 26, 26, 0.4);
  }

  &__title,
  &__note,
  &__format {
    transition: 0.25s;
  }

  &__icon {
    @include w480 {
      grid-column: 1 / 2;
      max-width: 24px;
      min-width: 24px;
      max-height: 24px;
      min-height: 24px;
    }
  }

  &__input {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  // &__text,
  // &__icon,
  // &__formats {
  //   display: block;
  //   margin: 0 auto;
  //   text-align: center;
  // }
}
