@use "../../common/styles/_mixins.scss" as *;

.EventForm {
  display: grid;
  grid-template-columns: 1fr;
  gap: 14px 0;

  @include w480 {
    gap: 10px 0;
  }

  &__title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;

    margin-bottom: 10px;
    margin-top: 16px;

    color: #000000;

    &:nth-child(2) {
      margin-top: 0;
    }
  }

  &__textarea {
    min-height: 218px;

    @include w480 {
      min-height: 192px;
    }
  }

  &__label {
    position: relative;
  }

  &__counter {
    position: absolute;
    right: 6px;
    bottom: 4px;
  }

  &__costList {
    display: grid;
    gap: 14px;
    @include w480 {
      gap: 25px;
    }
  }

  &__itemTwoRow {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    @include w480 {
      grid-template-columns: 1fr;
    }
  }

  &__itemCoast {
    display: grid;
    gap: 7px 14px;
    grid-template-columns: 1fr 0.27fr 30px;
    gap: 10px;

    @include w480 {
      grid-template-columns: 1fr;

      gap: 7px 10px;

      grid-template-areas:
        "name name name name name"
        "price price price trash trash";

      &_name {
        grid-area: name;
      }

      &_price {
        grid-area: price;
      }

      &_trash {
        grid-area: trash;
      }
    }
  }

  &__itemCoastButton {
    background: none;
    border: none;
    position: relative;
    // aspect-ratio: 1/1;

    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      path {
        stroke: gray;
      }
    }
    @include w480 {
      padding: 0 10px;
      // background: #c50e0e33;
      // height: 30px;
      // border-radius: 20px;
      // width: 20%;
      // justify-self: center;

      // &:disabled {
      //   background: #5b525269;

      //   path {
      //     stroke: black;
      //   }
      // }
    }
    &_plus {
      width: 100%;
      background-color: #7b7b7b37;
      height: 50px;
      border-radius: 10px;
      margin-top: 20px;

      font-size: 18px;
      font-family: var(--font-proxima) !important;
    }
    &_hide {
      display: none;
    }
  }

  &__itemSiteLinks {
    display: grid;
    gap: 7px 14px;
    grid-template-columns: 1fr 1fr;

    @include w480 {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }

  &__label_cost {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  &__switch {
    justify-self: end !important;
    margin-right: 0 !important;
  }

  &__input_cost_wrapper {
    grid-column: 1 / 3;
  }

  &__input_hide {
    display: none;
  }
}

.InputCounter {
  & > * {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    bottom: 4px;
  }

  &__from,
  &__till,
  &__divider {
    color: #d4d3d9;
  }

  &__from_active {
    color: #4ab538;
  }

  &__till_active {
    color: #ee2c2c;
  }
}
