.ChatChipButton {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.05px;
  padding: 11px 14px;
  color: #303030;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 100px;
  width: fit-content;
  display: flex;
  gap: 0 10px;
  white-space: nowrap;

  &_Hovered:hover,
  &_Checked {
    background: #d6d8db;
    border-color: #666666;
  }
}
