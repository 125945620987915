.CardHeader {
  display: flex;
  justify-content: space-between;
}

.ContactScore {
  align-items: center;
  display: flex;
  position: relative;

  & > *:nth-child(2),
  & > *:nth-child(3) {
    position: absolute;
  }

  & > *:nth-child(2) {
    left: 40px;
    z-index: -1;
  }

  & > *:nth-child(3) {
    left: 79px;
    z-index: -2;
  }
}

.ContactStatus {
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  &__Status {
    border-radius: 100px;
    color: #fff;
    padding: 2px 8px;
    height: 19px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    width: fit-content;
  }

  &__Status_Purple {
    background-color: #8d68fc;
  }

  &__Status_Green {
    background-color: #5cd877;
  }

  &__Status_Orange {
    background-color: #ff7f5f;
  }

  &__Date {
    color: #333236;
    opacity: 50%;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.62px;
  }
}
