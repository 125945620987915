.StackRomantic {
  &__Label {
    color: #000000 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19.49px;
  }

  &__Description {
    font-size: 13px !important;
    line-height: 15.83px !important;
  }
}
