.SigninResetPassword__control,
.SigninResetPassword__control-button {
  width: 338px;
}

.SigninResetPassword__control {
  margin-top: 50px;
}

@media (max-width: 480px) {
  .SigninResetPassword__control,
  .SigninResetPassword__control-button {
    width: 100%;
  }

  .SigninResetPassword__control {
    margin-top: 40px;
  }
}
