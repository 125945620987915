.Image {
  position: relative;

  &__Preview {
    object-fit: cover;
  }

  &__Preview_Hide {
    opacity: 0;
  }

  &,
  &__Preview,
  &__Skeleton {
    border-radius: 50%;
    max-height: 55px;
    min-height: 55px;
    max-width: 55px;
    min-width: 55px;
  }

  &__Skeleton {
    background-color: #8ec5fc;
    background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
    background-size: 400% 400%;
    animation: gradient 2s ease infinite;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
  }

  &__Skeleton_Hide {
    display: none;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
