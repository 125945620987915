@use "src/common/styles/_mixins.scss" as *;

.BaseDropDown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  &-white {
    path {
      stroke: #fff;
    }
  }
  &__children {
    display: flex;
    align-items: center;
    gap: 0 12px;
  }

  &__menu {
    user-select: none;
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    padding: 6px 0;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);

    //animations
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: 0.2s;
  }

  &__active {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
  }

  &__menu_list {
    display: grid;
    gap: 0px;
    width: max-content;
  }

  &__menu_item {
    padding: 8px 20px;
    text-transform: initial;
    width: 100%;
    font-size: 16px;

    &:hover {
      background-color: #f7f7f7;
      cursor: pointer;
    }
  }

  &__icon {
    margin-bottom: 2px;
    margin-left: 5px;
    transition: 0.2s;
    transform: rotate(0);
  }

  &__rotate {
    transform: rotate(180deg);
  }

  &__Arrow {
    transform: rotate(180deg);
    transition: 0.4s;
  }

  &__Arrow_Rotate {
    transform: rotate(0);
  }
}
