.CredentialsForm {
  margin: 100px 0 0 0;
}

.CredentialsForm__content {
  margin-top: 30px !important;
  max-width: 595px;
}

@media (max-width: 1024px) {
  .CredentialsForm {
    margin: 70px 0 0 0;
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .CredentialsForm {
    margin: 60px 0 0 0;
  }

  .CredentialsForm__content {
    margin-top: 20px;
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .CredentialsForm {
    margin: 30px 0 0 0;
  }

  .CredentialsForm__content {
    max-width: 100%;
  }
}
