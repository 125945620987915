@use "src/common/styles/_mixins.scss" as *;

.ChatControls {
  background-color: #e8e9eb;
  border-radius: 32px;
  padding: 12px;

  &_Empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
  }

  @include ifmobile {
    position: fixed;
    border-radius: unset;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &__Hint {
    color: #303030;
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    margin: 0 78px 8px 0;
    text-align: right;

    @include ifmobile {
      margin: 0 0 12px 0;
    }
  }

  &__Content {
    display: flex;
    gap: 0 26px;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__Content_Empty {
    align-items: center;
    justify-content: center;
    gap: unset;
  }

  &__SendButton_Desktop {
    @include ifmobile {
      display: none;
    }
  }

  &__SendButton_Mobile {
    display: none;
    @include ifmobile {
      display: block;
    }
  }
}

.ChatListAnswers {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: flex-end;
  width: 100%;
}

.ChatControlsHide {
  display: none;
}

.ChatControlsPlaceholder {
  color: #808080;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}
