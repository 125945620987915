@use '../../../common/styles/_extends.scss' as *;
@use '../../../common/styles/_mixins.scss' as *;

.base-button {
  font-family: 'Roboto', 'sans-serif';
  border-width: 0;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  width: fit-content;
  text-transform: unset;
}

.base-button-disabled {
  background-color: #f8f8f8 !important;
  border-color: #f8f8f8 !important;
  color: rgba($color: #000000, $alpha: 0.4) !important;
}

.base-button-shape {
  text-align: center;
  border-radius: 40px;
  padding: 21px 53px;
}

.base-black-btn,
.base-empty-btn,
.base-white-btn {
  transition: 0.15s;
}

.base-empty-btn {
  @extend .base-button;
  @extend .base-button-shape;

  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: var(--main-color-white);
  background-color: inherit;
  border: 1px solid var(--main-color-white);
}

.base-white-btn {
  @extend .base-button;
  @extend .base-button-shape;

  background-color: var(--main-color-white);
  border: 1px solid var(--main-color-black);
  color: var(--main-color-black);

  &:hover {
    background-color: var(--main-color-black);
    border: 1px solid var(--main-color-white);
    color: var(--main-color-white);
  }
}

.base-black-btn {
  @extend .base-button;
  @extend .base-button-shape;
  background-color: var(--main-color-black);
  color: var(--main-color-white);
  border: 1px solid var(--main-color-black);
}

.base-black-btn-landing {
  @extend .base-black-btn;
}

.base-white-btn-landing {
  @extend .base-white-btn;
}

.base-white-btn-landing,
.base-black-btn-landing {
  border-radius: calc(40px / 1920px * 100vw);
  padding: calc(24px / 1920px * 100vw) calc(53px / 1920px * 100vw);
  font-size: calc(24px / 1920px * 100vw) !important;
  text-transform: uppercase;
  font-weight: 700;
}

.base-black-btn-landing:hover {
  color: var(--main-color-black);
  background-color: var(--main-color-white);
  border-color: var(--main-color-black);
}

.base-white-btn-landing:hover {
  color: var(--main-color-white);
  background-color: var(--main-color-black);
  border-color: var(--main-color-white);
}

.base-grey-btn {
  @extend .base-button;
  @extend .base-button-shape;
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--main-color-white);
}

.base-button-go-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
  rotate: 180deg;
  cursor: pointer;
  border-width: 0;
  text-transform: unset;
  background-color: inherit;
  padding: 12px 0;
}

.base-button-continue {
  @extend .base-black-btn;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding-left: 42px;
  padding-right: 42px;

  &__arrow {
    margin-left: 14px;
  }
}

.base-button-with-arrow {
  border: 1px solid black;
  background-color: rgb(255, 255, 255);
  width: 56px;
  height: 44px;
  border-radius: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 13px;
  bottom: 13px;
  z-index: 2;
  cursor: pointer;

  &__area {
    align-items: center;
    display: flex;
    z-index: 1;
  }

  &__img {
    z-index: 0;
  }
}

.base-button-new-style {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 12px 57px;
  font-family: "Proxima Nova", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  text-transform: unset;
  line-height: normal;
  border-radius: 8px;
  width: fit-content;
  justify-content: center;
}

.base-button-new-style-white {
  @extend .base-button-new-style;

  border: 1px solid #000000;
  color: #000000;
  background-color: #ffffff;

  &:hover {
    background-color: #F7F7F7;
  }
  &:active {
    background-color: #F0F0F0;
  }
}

.base-button-new-style-black {
  @extend .base-button-new-style;
  border: 1px solid transparent;
  color: #FFF;
  background-color: var(--main-color-gray-umber);

  &:hover {
    background-color: #4A4A4A;
  }
  &:active {
    background-color: #292929;
  }

}
.base-button-new-style-black-with-white-arrow {
  @extend .base-button-new-style-black;
  padding: 12px 40px;
  background-color: var(--main-color-gray-umber);

  &__area {
    align-items: center;
    display: flex;
  }

  &__img {
    width: 40px;
  }

}

.base-button-with-white-arrow {
  position: absolute;
  z-index: 1;
  left: 13px;
  width: auto;
}

.base-text-btn {
  @extend .base-button;
  background-color: unset;
  text-align: center;

  @include w768 {
    font-size: 16px;
  }
}

@media (max-width: 1440px) {
  .base-button {
    font-size: 18px;
    border-radius: 40px;
  }
}

@media (max-width: 480px) {
  .base-button {
    font-size: 16px;
    border-radius: 100px;
    font-weight: 400;
  }

  .base-white-btn-landing,
  .base-black-btn-landing {
    font-size: 16px !important;
    padding-top: 11px;
    padding-bottom: 11px;
    text-transform: uppercase;
  }

  .base-button-continue {
    display: none;
  }

  .base-button-with-arrow {
    display: flex;
  }

  .base-button-with-arrow {
    bottom: 15px;
  }

  .base-button-with-white-arrow {
    bottom: 25px;
  }
}