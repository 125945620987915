.base-textarea {
  border: 1px solid var(--main-color-gray);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  padding: 16px 19px;
  resize: none;
  max-width: 100%;
  min-width: 100%;

  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  &::-webkit-scrollbar {
    height: 50px;
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--main-color-white);
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--main-color-black);
    border-radius: 9px;
  }

  &_required {
    border: 1px solid var(--main-color-red);
  }
}