@use "src/common/styles/_mixins.scss" as *;

@keyframes showanim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ConfirmEmailModal {
  animation: 0.4s ease-in 0s showanim;
  color: #797983;
  display: flex;
  flex-flow: column;
  gap: 20px 0;
  max-width: 505px;

  @include iftablet {
    max-width: 440px;
  }

  @include ifmobile {
    max-width: 94%;
  }

  &__Header {
    text-align: center;
  }

  &__Title {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.92px;

    @include ifmobile {
      font-size: 16px;
      line-height: 19.49px;
    }

    &_Bold {
      color: #3e3e3e;
    }
  }

  &__Subtitle {
    margin-top: 12px;

    @include iftablet {
      line-height: 19.49px;
    }
  }

  &__Form {
    display: flex;
    justify-content: center;
    gap: 0 10px;
  }

  &__Footer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }
}

.ConfirmEmailModalForm {
  &__Input {
    font-size: 26px;
    line-height: 30px;
    max-height: 58px;
    min-height: 58px;
    max-width: 58px;
    min-width: 58px;
    text-align: center;
  }
}

.ConfirmEmailModalFooter {
  &__Resend {
    color: #3d3d3d;
  }

  &__Button {
    margin: 20px auto 0 auto;
  }
}
