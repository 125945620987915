@use "../../../common/styles/_mixins.scss" as *;
@use "../../../common/styles/_variables.scss" as *;
@use "../../../common/styles/extends" as *;

.AccountHeader {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  width: 100%;

  @extend %header;

  @include iftablet {
    grid-template-columns: 1fr auto;
  }

  &__navigation {
    margin: 0 auto;

    @include w768 {
      display: none;
    }
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item:nth-child(1n + 2) {
    margin-left: 50px;
  }

  &__link {
    font: 600 16px var(--font-proxima), sans-serif;
    line-height: 19px;
    text-transform: uppercase;
  }

  @include w768 {
    margin-right: 0;
  }

  &__me {
    text-transform: uppercase;

    &-white {
      color: #fff;
    }
  }

  &_Bordered {
    @include ifmobile {
      border-bottom: 1px solid #1a1a1a1f;
    }
  }
}
