.BaseInputSocials {
  display: flex;
  align-items: center;
  height: 58px;
  //padding-left: 53px;
  border: 1px solid var(--main-color-gray);
  background-color: #f3f4f6;
  border-radius: 4px;

  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &_required {
    border: 1px solid var(--main-color-red);
  }

  &__icon-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 53px;
    user-select: none;
  }

  &__icon {
    width: 20px;
  }

  &__input {
    height: 100%;
    width: 100%;
    border: none;
    padding: 10px 16px;
    font-size: 16px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    //background: rgba(255, 255, 255, 0.8);
    font-family: var(--font-proxima);

    &:focus {
      color: var(--main-color-black);
      outline: 0;
    }

    &::-webkit-input-placeholder {
      color: var(--main-color-dark-grey);
    }
    &:disabled {
      background-color: #ffffff;
    }
  }
}

@media (max-width: 480px) {
  .BaseInputSocials {
    height: 48px;
  }
}
