@use "../../common/styles/mixins" as *;

.ImagePreview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &,
  &__image {
    width: 387px;
    height: 240px;
    object-fit: cover;

    @include w480 {
      width: 100%;
      height: z(227.87px, 360px);
    }
  }

  &--isBig {
    @include w768 {
      width: 700px;
    }
    @include w480 {
      width: 100%;
      height: 189px;
    }
    width: 718px;
  }

  &:hover > &__tools {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tools {
    background: rgba(0, 0, 0, 0.5);
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  &__tools_hide {
    display: none;
    z-index: -1111;
  }

  &__pencil,
  &__trash_bin {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--main-color-white);
    max-width: 40px;
    min-width: 40px;
    max-height: 40px;
    min-height: 40px;
  }

  &__pencil:hover > &__pencil_icon > * {
    fill: blue;
  }

  &__trash_bin {
    margin-left: 16px;
  }

  &__trash_bin:hover > &__trash_bin_icon > * {
    stroke: #ef4343;
  }

  &__trash_bin_icon {
    max-height: 18px;
    min-height: 18px;
    max-width: 16px;
    min-width: 16px;
  }
}
