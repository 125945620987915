@use "../../common/styles/_mixins.scss" as *;

.EventList {


    &__item:nth-child(1) {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    &__item:nth-child(2) {
        margin-top: 10px;
        max-height: 488px;
        min-height: 488px;
        background: #FAFAFA;
        border: 1px solid #D4D3D9;
        border-radius: 4px;
        padding: 20px 10px 20px 20px;

        @include w768 {
            max-height: 380px;
            min-height: 380px;
        }
    }

    &__content {
        overflow: hidden;
        overflow-y: auto;
        max-height: 440px;

        @include w768 {
            max-height: 327px;
        }
    }

    // LIST SCROLL
    &__content::-webkit-scrollbar {
        height: 50px;
        width: 4px;
    }

    &__content::-webkit-scrollbar-track {
        background: #D9D9D9;
        border-radius: 9px;
    }

    &__content::-webkit-scrollbar-thumb {
        background-color: var(--main-color-black);
        border-radius: 9px;
    }
}