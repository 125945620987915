.base-typography {
  font-family: 'Proxima Nova', sans-serif;
  margin: 0;
}

.form-link {
  @extend .base-typography;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--main-color-black);
}

.landing-title-lvl-1 {
  font-weight: 900;
  font-style: italic;
  font-size: calc(40px / 1920px * 100vw);
  line-height: calc(46.88px / 1920px * 100vw);
  text-transform: uppercase;
}

.landing-title-lvl-2 {
  font-weight: 700;
  font-size: calc(38px / 1920px * 100vw);
  line-height: calc(45px / 1920px * 100vw);
}

.landing-title-lvl-3 {
  font-weight: 900;
  font-size: calc(32px / 1920px * 100vw);
  line-height: calc(38px / 1920px * 100vw);
}

.landing-title-lvl-4 {
  font-weight: 500;
  font-size: calc(32px / 1920px * 100vw);
  line-height: calc(38px / 1920px * 100vw);
}

.landing-subtitle-lvl-1 {
  font-weight: 400;
  font-size: calc(28px / 1920px * 100vw);
  line-height: calc(33px / 1920px * 100vw);
}

.landing-text-lvl-1 {
  font-weight: 400;
  font-size: calc(24px / 1920px * 100vw);
  line-height: calc(33px / 1920px * 100vw);
}

.profile-title {
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
}

.profile-sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
}

.profile-text {
  font-weight: 400;
  font-size: calc(16px / 1920px * 100vw);
  line-height: calc(19.5px / 1920px * 100vw);
}

.profile-fill-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
}

.profile-fill-subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

@media (max-width: 1024px) {
  .landing-text-lvl-1 {
    font-size: calc(14px / 1024px * 100vw);
  }
}

@media (max-width: 768px) {
  .form-link {
    font-size: 14px;
  }

  .profile-fill-subtitle {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .landing-title-lvl-1 {
    font-size: 24px;
    line-height: 28px;
  }

  .landing-title-lvl-2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  .landing-subtitle-lvl-1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  .landing-text-lvl-1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  .profile-title {
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
  }

  .profile-sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20.5px;
  }

  .profile-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}