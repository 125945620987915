.AccountFooter {
    display: flex;
    position: relative;
    height: 90px;
    width: 100%;

    &__img {
        height: 90px;
        width: 100%;
    }
}