@use "src/common/styles/_mixins.scss" as *;

.RegistrationLayout {
  padding: 20px 40px 100px 40px;
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;

  @include iflaptop {
    padding: 20px 60px 60px;
  }

  @include iftablet {
    padding: 20px 34px 60px 34px;
  }

  @include ifmobile {
    padding: 82px 14px 42px;
    background: unset !important;
  }

  & > *:nth-child(1) {
    padding: 7.5px 0;

    @include iflaptop {
      padding: 0;
    }

    @include ifmobile {
      background: #fff;
      position: fixed;
      width: 100%;
      height: 60px;
      top: 0;
      left: 0;
      padding: 0 14px;
      display: flex;
      align-items: center;
      z-index: 5;
    }
  }

  & > *:nth-child(2) {
    max-width: 600px;
    width: 100%;
    justify-self: center;
  }
}
