.SigninEnterPassword__button,
.SigninEnterPassword__control {
  width: 339px;
}

.SigninEnterPassword__button {
  margin-top: 50px;
}

.SigninEnterPassword__validations {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .SigninEnterPassword__button {
    margin-top: 40px;
  }
}

@media (max-width: 479px) {
  .SigninEnterPassword__button,
  .SigninEnterPassword__control {
    width: 100%;
  }

  .SigninEnterPassword__button {
    margin-top: 44px;
  }

  .SigninEnterPassword__validations {
    margin-top: 30px;
  }
}
