.base-tag {
  background-color: var(--main-color-black);
  border-radius: 10px;
  color: var(--main-color-white);
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  padding: 8px 20px;
  width: fit-content;
  opacity: 0.7;
  max-height: 30px;
}

.base-tag_active {
  opacity: 1;
}
