@use "src/common/styles/_mixins.scss" as *;

.base-select {
  border: 1px solid var(--main-color-gray);
  border-radius: 4px;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  @include ifmobile {
    height: 48px;
  }

  &_focus {
    border-color: #000000 !important;
  }

  &_required {
    border-color: var(--main-color-red);
  }

  &__inner {
    padding: 1px 0;
    width: 100%;
  }

  &__input {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    line-height: 19.49px;
    padding: 19px 0px 20px 16px;
    font-family: var(--font-proxima);
    font-weight: 400;

    @include ifmobile {
      font-size: 14px;
      line-height: 17.05px;
    }
  }

  &__placeholder {
    color: var(--main-color-dark-grey);
  }

  &__controls {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 20px;
    right: 13px;
    top: 0;

    & svg > * {
      stroke: #000000;
    }
  }

  &__list {
    position: absolute;
    top: 110%;
    background-color: var(--main-color-white);
    box-shadow: 1px 1px 1px var(--main-color-gray),
      -1px -1px 1px var(--main-color-gray);
    border-radius: 4px;
    width: 100%;
    padding: 4px 0;
    z-index: 5;
  }

  &__item {
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 12px;
  }

  &__item:hover {
    background-color: #f5f5f5;
  }
}

@media (max-width: 480px) {
  .base-select {
    height: 48px;
  }
}
