@use "../../../common/styles/_mixins.scss" as *;

.BaseContainer {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;
  height: 100%;

  @include w1024 {
    padding: 0 60px;
  }

  @include w768 {
    padding: 0 34px;
  }

  @include w480 {
    padding: 0 10px;
  }
}
