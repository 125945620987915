.CategorySelector {
  display: flex;
  justify-content: center;
  width: 100%;
  // padding: 0 15px;
  padding: 0 10px;
  position: relative;

  &__Labels {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__Label {
    position: absolute;
    color: #909090;
  }

  &__Label:nth-child(1) {
    left: 7.1px;
    bottom: -11.1px;
  }

  &__Label:nth-child(2) {
    right: 2.5px;
    bottom: -11.1px;
  }

  &__Label_Zero {
    left: 5px !important;
  }
}

.CategorySelectorDisabled {
  .Mui-disabled {
    background-color: #bdbdbd !important;
    & > * {
      color: #bdbdbd !important;
    }
  }

  & > * {
    background-color: #bdbdbd !important;
    color: #bdbdbd !important;

    & > * {
      color: #bdbdbd !important;
    }
  }
}
