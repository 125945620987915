/* This stylesheet generated by Transfonter (https://transfonter.org) on June 21, 2017 9:51 AM */

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-LightIt.eot");
  src: local("Proxima Nova Light Italic"), local("ProximaNova-LightIt"),
    url("ProximaNova-LightIt.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-LightIt.woff") format("woff"),
    url("ProximaNova-LightIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-Extrabld.eot");
  src: local("Proxima Nova Extrabold"), local("ProximaNova-Extrabld"),
    url("ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-Extrabld.woff") format("woff"),
    url("ProximaNova-Extrabld.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-Regular.eot");
  src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
    url("ProximaNova-Regular.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-Regular.woff") format("woff"),
    url("ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-Semibold.eot");
  src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
    url("ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-Semibold.woff") format("woff"),
    url("ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-ThinIt.eot");
  src: local("Proxima Nova Thin Italic"), local("ProximaNova-ThinIt"),
    url("ProximaNova-ThinIt.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-ThinIt.woff") format("woff"),
    url("ProximaNova-ThinIt.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-SemiboldIt.eot");
  src: local("Proxima Nova Semibold Italic"), local("ProximaNova-SemiboldIt"),
    url("ProximaNova-SemiboldIt.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-SemiboldIt.woff") format("woff"),
    url("ProximaNova-SemiboldIt.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-RegularIt.eot");
  src: local("Proxima Nova Regular Italic"), local("ProximaNova-RegularIt"),
    url("ProximaNova-RegularIt.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-RegularIt.woff") format("woff"),
    url("ProximaNova-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-Black.eot");
  src: local("Proxima Nova Black"), local("ProximaNova-Black"),
    url("ProximaNova-Black.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-Black.woff") format("woff"),
    url("ProximaNova-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-Bold.eot");
  src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
    url("ProximaNova-Bold.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-Bold.woff") format("woff"),
    url("ProximaNova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNovaT-Thin.eot");
  src: local("Proxima Nova Thin"), local("ProximaNovaT-Thin"),
    url("ProximaNovaT-Thin.eot?#iefix") format("embedded-opentype"),
    url("ProximaNovaT-Thin.woff") format("woff"),
    url("ProximaNovaT-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-BoldIt.eot");
  src: local("Proxima Nova Bold Italic"), local("ProximaNova-BoldIt"),
    url("ProximaNova-BoldIt.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-BoldIt.woff") format("woff"),
    url("ProximaNova-BoldIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-ExtrabldIt.eot");
  src: local("Proxima Nova Extrabold Italic"), local("ProximaNova-ExtrabldIt"),
    url("ProximaNova-ExtrabldIt.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-ExtrabldIt.woff") format("woff"),
    url("ProximaNova-ExtrabldIt.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-Light.eot");
  src: local("Proxima Nova Light"), local("ProximaNova-Light"),
    url("ProximaNova-Light.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-Light.woff") format("woff"),
    url("ProximaNova-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("ProximaNova-BlackIt.eot");
  src: local("Proxima Nova Black Italic"), local("ProximaNova-BlackIt"),
    url("ProximaNova-BlackIt.eot?#iefix") format("embedded-opentype"),
    url("ProximaNova-BlackIt.woff") format("woff"),
    url("ProximaNova-BlackIt.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
