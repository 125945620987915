@use "../../common/styles/_mixins.scss" as *;

.DefaultAccount {
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;

  &__body {
    margin-top: 45.5px;

    @include iflaptop {
      margin-top: 32px;
    }

    @include iftablet {
      margin-top: 42px;
    }

    @include ifmobile {
      margin-top: 12px;
    }
  }

  &__body_lines {
    background: top / cover no-repeat url("./horizontal-lines-1920.webp");
    padding-top: 0;

    @include ifmobile {
      background: unset;
      margin-top: 0;
    }
  }

  &__footer {
    margin-top: 53px;
  }

  &__header {
    z-index: 20;
  }
}
